<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="enroll_body">
      <div class="enroll_list">
        <div
          class="grade_list_item"
          v-for="(item, index) in listShowData"
          :key="index"
          @click="toCheckExaminationHallFn(item.id)"
        >
          <!-- <img
            class="grade_list_item_img"
            src="../../assets/image/login_img01.png"
          /> -->
          <div class="grade_list_item_title">{{ item.area }}</div>
        </div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/enroll/checkArea.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "CheckArea",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "考试报名", // 顶部组件参数设置
      listShowData: [], // 列表数据
      gradeId: "",
    };
  },
  created: function () {
    let v = this.$route.params.gradeId;
    if (v != undefined && v != "") {
      this.gradeId = v;
      this.$store.commit("set_checkedGradeId", v);
    } else {
      this.gradeId = this.$store.state.checkedGradeId;
    }
    this.getListDataFn();
  },
  methods: {
    // 查询考级列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/examareaList";
      let data = {
        category_id: this.gradeId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.listShowData = response.area_list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 选择考场
    toCheckExaminationHallFn: function (areaId) {
      this.$router.push({
        name: "EnrollList",
        params: { areaId: areaId },
      });
    },
  },
};
</script>
