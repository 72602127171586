<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="order_body">
      <!-- 考生信息 -->
      <div class="content_card">
        <img
          class="content_card_icon"
          src="../../assets/image/success_icon01.png"
          alt="提示"
        />
        <div class="content_card_tips">报名成功</div>
        <div class="content_card_tips_g">
          （支付成功7天内或报名截止前可申请退款）
        </div>
        <div class="content_card_btn_box">
          <div class="content_card_btn" @click="toOrderDetailFn()">
            查看详情
          </div>
          <div class="content_card_btn" @click="toHomeFn()">返回首页</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/order/orderSuccess.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";

export default {
  name: "OrderSuccess",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "提示", // 顶部组件参数设置
      orderId: "", // 订单Id
    };
  },
  created: function () {
    let orderId = this.$route.params.orderId;
    if (orderId != undefined && orderId != "") {
      this.orderId = orderId;
    } else {
      this.orderId = this.$store.state.watchOrderId;
    }
  },
  methods: {
    // 返回首页
    toHomeFn: function () {
      this.$router.push({
        name: "Home",
      });
    },
    // 查看详情
    toOrderDetailFn: function () {
      if (this.orderId != "") {
        this.$router.push({
          name: "OrderDetail",
          params: {
            orderId: this.orderId,
          },
        });
      }
    },
  },
};
</script>
