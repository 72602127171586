<template>
  <div class="home_top">
    <img
      class="home_top_back"
      src="../assets/image/black_icon01.png"
      alt="返回"
      @click="toBackFn()"
    />
    <div class="home_top_title">{{ topTitle }}</div>
  </div>
</template>

<script>
export default {
  name: "HomeTop",
  props: {
    topTitle: String, // 标题名称
    setBackLinkKey: Boolean, // 是否自定义跳转
    backLinkName: String, // 自定义跳转路由名称
    backLinkParams: Object, // 自定义跳转路由参数
  },
  data() {
    return {};
  },
  created: function () {},
  methods: {
    toBackFn: function () {
      if (!this.setBackLinkKey) {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          name: this.backLinkName,
          params: this.backLinkParams,
        });
      }
    },
  },
};
</script>
