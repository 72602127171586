<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="check_body">
      <!-- 内容 -->
      <div class="check_content">
        <!-- 添加考生 -->
        <div class="check_add_user_btn" @click="toAddUserFn()">
          <img
            class="check_add_user_icon"
            src="../../assets/image/add_icon01.png"
          />
          <div class="check_add_user_name">新增考生</div>
        </div>
        <!-- 考生列表 -->
        <div class="check_user_list">
          <div
            class="check_user_list_item"
            v-for="(item, index) in userListShow"
            :key="index"
            @click="checkUserFn(item)"
          >
            <div class="check_user_list_item_left">
              <img
                class="check_user_list_item_icon"
                src="../../assets/image/edit_icon01.png"
                @click.stop="editUserFn(item.id)"
              />
              <div class="check_user_list_item_name">{{ item.name }}</div>
            </div>
            <div v-if="item.access_enroll == 1" :class="setClassFn(item.id)">
              <img
                class="check_user_list_item_check_icon"
                src="../../assets/image/checked_icon02.png"
              />
            </div>
            <div
              v-if="item.access_enroll == 2"
              class="check_user_list_item_check is_disabled"
            ></div>
          </div>
        </div>
      </div>
      <!-- 确认 -->
      <div
        :class="[
          checkedUserId != []
            ? 'check_bottom_btn has_checked_id_btn'
            : 'check_bottom_btn',
        ]"
        @click="toCheckTimeFn()"
      >
        确认
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/enroll/checkUser.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "CheckUser",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "考生选择", // 顶部组件参数设置
      enrollId: "", // 报名活动Id
      userListShow: [], // 考生列表数据
      checkedUserId: [], // 已勾选考生id
      gradeList: [
        {
          value: 1,
          msg: "技能一级",
        },
        {
          value: 2,
          msg: "技能二级",
        },
        {
          value: 3,
          msg: "技能三级",
        },
        {
          value: 4,
          msg: "技能四级",
        },
        {
          value: 5,
          msg: "技能五级",
        },
        {
          value: 6,
          msg: "技能六级",
        },
      ],
    };
  },
  created: function () {
    this.enrollId = this.$store.state.checkedEnrollId;
    this.getListDataFn();
  },
  methods: {
    // 设置Class
    setClassFn(id) {
      if (this.checkedUserId.findIndex((item) => item == id) > -1) {
        return "check_user_list_item_check is_checked";
      } else {
        return "check_user_list_item_check";
      }
    },
    // 查询考生列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/selectExamStudents";
      let data = {
        exam_id: this.enrollId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.userListShow = response.stu_infos;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 勾选/取消考生
    checkUserFn: function (item) {
      let id = item.id;
      let access_enroll = item.access_enroll;
      let exam_level = item.exam_level;
      let real_level = item.real_level;
      let that = this;
      let index = this.checkedUserId.findIndex((item) => item == id);
      if (access_enroll == 1) {
        if (index > -1) {
          this.checkedUserId.splice(index, 1);
        } else {
          this.checkedUserId.push(id);
        }
      } else {
        let levelIndex = this.gradeList.findIndex(
          (item) => item.value == exam_level - 1
        );
        let examLevelIndex = this.gradeList.findIndex(
          (item) => item.value == exam_level
        );
        if (real_level > exam_level) {
          Toast({
            message:
              "该考生高于" + that.gradeList[examLevelIndex].msg + ",请重新选择",
            duration: 3000,
          });
        } else {
          Toast({
            message:
              "该考生尚未达到" + that.gradeList[levelIndex].msg + ",请重新选择",
            duration: 3000,
          });
        }
      }
    },
    // 前往选择时间
    toCheckTimeFn: function () {
      if (this.checkedUserId != "") {
        this.$router.push({
          name: "CheckTime",
          params: { userId: this.checkedUserId.join(",") },
        });
      }
    },
    // 添加考生
    toAddUserFn: function () {
      if (this.userListShow.length < 4) {
        this.$router.push({
          name: "AddUser",
          params: { source: 2 },
        });
      } else {
        Toast({
          message: "同一账户不能超过4个考生",
          duration: 3000,
        });
      }
    },
    // 编辑考生
    editUserFn: function (id) {
      this.$router.push({
        name: "EditUser",
        params: { userId: id, source: 2 },
      });
    },
  },
};
</script>
