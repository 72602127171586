<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="enroll_body">
      <!-- 考试信息 -->
      <div class="enroll_detail_card">
        <div class="enroll_detail_top_item">
          <img
            class="enroll_detail_top_img"
            :src="baseAPIUrl + enrollData.image_path"
            alt="封面"
          />
          <div class="enroll_detail_top_title">{{ enrollData.title }}</div>
        </div>
        <div class="enroll_detail_top_item">
          <div class="enroll_detail_top_time_box">
            <img
              class="enroll_detail_top_icon"
              src="../../assets/image/time_icon01.png"
            />
            <div class="enroll_detail_top_time">{{ enrollData.exam_date }}</div>
          </div>
          <div class="enroll_detail_top_price">
            ￥ {{ enrollData.exam_money }}
          </div>
        </div>
        <div class="enroll_detail_top_item_left">
          <img
            class="enroll_detail_top_icon"
            src="../../assets/image/address_icon01.png"
            style="height: auto"
          />
          <div class="enroll_detail_top_address_box">
            <div class="enroll_detail_top_address">
              <div class="enroll_detail_top_address_g">
                {{ enrollData.area_name }}
              </div>
              <div class="enroll_detail_top_address_b">
                {{ enrollData.place }}
              </div>
            </div>
            <div class="enroll_detail_top_address_detail">
              {{ enrollData.address }}
            </div>
          </div>
          <div class="enroll_detail_top_address_icon_box">
            <img
              class="enroll_detail_top_address_icon"
              @click="openMapFn()"
              src="../../assets/image/address_icon02.png"
            />
            <div class="enroll_detail_top_address_icon_name">地图</div>
          </div>
        </div>
      </div>
      <!-- 考试介绍 -->
      <div
        class="enroll_detail_card"
        v-if="enrollData.detail != '' || enrollData.notice != ''"
      >
        <div class="enroll_detail_tab_box">
          <div
            :class="[
              tabShowKey == 1
                ? 'enroll_detail_tab enroll_detail_tab_isActive'
                : 'enroll_detail_tab',
            ]"
            @click="changeTabFn(1)"
            v-if="enrollData.detail != ''"
          >
            简介
          </div>
          <div
            :class="[
              tabShowKey == 2
                ? 'enroll_detail_tab enroll_detail_tab_isActive'
                : 'enroll_detail_tab',
            ]"
            @click="changeTabFn(2)"
            v-if="enrollData.notice != ''"
          >
            须知
          </div>
        </div>
        <div
          class="enroll_detail_tab_content"
          v-show="tabShowKey == 1"
          v-html="enrollData.detail"
        ></div>
        <div
          class="enroll_detail_tab_content"
          v-show="tabShowKey == 2"
          v-html="enrollData.notice"
        ></div>
      </div>
      <!-- 确认报名 -->
      <div class="enroll_detail_bottom" v-if="agreeBtnShow">
        <div
          :class="[
            agreeKey
              ? 'enroll_detail_bottom_btn agree_btn'
              : 'enroll_detail_bottom_btn',
          ]"
          @click="toCheckUserFn()"
        >
          报名
        </div>
        <!-- <div class="enroll_detail_bottom_clause_box">
          <div
            :class="[
              agreeKey
                ? 'enroll_detail_bottom_check_box agree_icon_box'
                : 'enroll_detail_bottom_check_box',
            ]"
            @click="changeAgreeKey()"
          >
            <img
              :class="[
                agreeKey
                  ? 'enroll_detail_bottom_check_icon agree_icon'
                  : 'enroll_detail_bottom_check_icon',
              ]"
              src="../../assets/image/checked_icon01.png"
            />
          </div>

          <div class="enroll_detail_bottom_clause_words">选中代表您已阅读</div>
          <div class="enroll_detail_bottom_clause">《考试须知》</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/enroll/enrollDetail.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";
// 引入微信JSSDK
import wx from "weixin-js-sdk";

export default {
  name: "EnrollDetail",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "报名详情", // 顶部组件参数设置
      enrollId: "", // 报名活动Id
      enrollData: {
        id: "",
        image_path: "",
        title: "",
        exam_date: "",
        area_name: "",
        exam_money: "",
        address: "",
        detail: "",
        notice: "",
      }, // 报名详情数据
      tabShowKey: 1, // 考试介绍选项卡显示
      agreeKey: true, // 是否已阅读
      agreeBtnShow: true, // 是否显示按钮
      baseAPIUrl: this.$store.state.baseAPIUrl,
      wxJSSDKConfig: {}, // 微信JSSDK配置
      realUrl: "",
      mapData: {
        center: { lng: 0, lat: 0 }, //中心坐标
        zoom: 17, //缩放级别
      }, //地图数据
    };
  },
  created: function () {
    let v = this.$route.params.enrollId;
    this.$store.commit("set_agreeEnrollKey", 1);
    if (v != undefined && v != "") {
      this.enrollId = v;
      this.$store.commit("set_checkedEnrollId", v);
    } else {
      this.enrollId = this.$store.state.checkedEnrollId;
    }
    let agreeBtnShow = this.$route.params.btnKey;
    if (agreeBtnShow != undefined && agreeBtnShow != "") {
      this.$store.commit("set_agreeBtnShow", agreeBtnShow);
      this.agreeBtnShow = this.$store.state.agreeBtnShow;
    } else {
      this.agreeBtnShow = this.$store.state.agreeBtnShow;
    }
    this.getDetailDataFn();
    this.realUrl = this.$store.state.baseUrl + this.$route.fullPath;
  },
  methods: {
    // 查询报名详情数据
    getDetailDataFn: function () {
      let that = this;
      let url = "/mobile/examDetail";
      let data = {
        exam_id: this.enrollId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.enrollData = response.exam_info;
            let locationData = {
              lng: Number(response.exam_info.longitude),
              lat: Number(response.exam_info.latitude),
            };
            that.mapData.center = locationData;
            if (that.enrollData.detail == "") {
              that.tabShowKey = 2;
            }
            if (that.enrollData.notice == "") {
              that.tabShowKey = 1;
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 切换选项卡
    changeTabFn: function (key) {
      this.tabShowKey = key;
    },
    // 勾选/取消已阅读
    changeAgreeKey: function () {
      this.agreeKey = !this.agreeKey;
    },
    // 前往选择考生
    toCheckUserFn: function () {
      // let that = this;
      // if (this.agreeKey == true) {
      //   this.$store.commit("set_agreeEnrollKey", true);
      //   this.$router.push({
      //     name: "CheckUser",
      //   });
      // } else {
      //   MessageBox({
      //     message: "请您阅读《考试须知》,勾选同意进行报名",
      //     confirmButtonText: "同意",
      //     showCancelButton: true,
      //   }).then((action) => {
      //     if (action == "confirm") {
      //       that.agreeKey = true;
      //     }
      //   });
      // }
      this.$store.commit("set_agreeEnrollKey", 2);
      this.$router.push({
        name: "CheckUser",
      });
    },
    // 调起地图
    openMapFn: function () {
      let that = this;
      this.getWXJSSDKConfigFn().then((res) => {
        if (res) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: that.wxJSSDKConfig.appid, // 必填，公众号的唯一标识
            timestamp: that.wxJSSDKConfig.time, // 必填，生成签名的时间戳
            nonceStr: that.wxJSSDKConfig.password, // 必填，生成签名的随机串
            signature: that.wxJSSDKConfig.signature, // 必填，签名
            jsApiList: ["openLocation"], // 必填，需要使用的 JS 接口列表
          });
          wx.ready(function () {
            let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
            let x = that.mapData.center.lng - 0.0065;
            let y = that.mapData.center.lat - 0.006;
            let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
            let wz = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
            let lon = z * Math.cos(wz);
            let lat = z * Math.sin(wz);
            wx.openLocation({
              latitude: lat, // 纬度，浮点数，范围为90 ~ -90
              longitude: lon, // 经度，浮点数，范围为180 ~ -180。
              name: that.enrollData.address, // 位置名
              address: "", // 地址详情说明
              scale: 25, // 地图缩放级别,整型值,范围从1~28。默认为最大
            });
          });

          wx.error(function (res) {
            if (
              res.errMsg == "config:invalid signature" &&
              res.realAuthUrl[0] != "" &&
              res.realAuthUrl[0] != undefined
            ) {
              that.realUrl = res.realAuthUrl[0];
            }
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
          });
        }
      });
    },
    // 获取WXJSSDK配置
    getWXJSSDKConfigFn: function () {
      let that = this;
      let data = {};
      data = {
        url: that.realUrl,
      };
      let url = "/mobile/getjssdkconfig";
      return new Promise(function (resolve, reject) {
        that.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              that.wxJSSDKConfig = response.signpackage;
              resolve(true);
            } else {
              Toast({
                message: response.msg,
                duration: 3000,
              });
              resolve(false);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
</script>
