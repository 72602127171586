<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="enroll_body">
      <!-- 报名列表 -->
      <div
        class="enroll_list"
        v-infinite-scroll="loadingListFn"
        infinite-scroll-disabled="listLoadingKey"
        infinite-scroll-distance="10"
        v-if="enrollListShow.length > 0"
      >
        <div
          class="enroll_list_item"
          v-for="(item, index) in enrollListShow"
          :key="index"
          @click="toDetailFn(item.id)"
        >
          <img
            class="enroll_list_item_img"
            :src="baseAPIUrl + item.image_path"
          />
          <div class="enroll_list_item_content">
            <div class="enroll_list_item_title">{{ item.title }}</div>
            <div class="enroll_list_item_center">
              <div class="enroll_list_item_address">{{ item.area_name }}</div>
              <div class="enroll_list_item_time">
                考试时间:{{ item.exam_date }}
              </div>
            </div>
            <div class="enroll_list_item_bottom">
              <div class="enroll_list_item_bottom_left">
                <div class="enroll_list_item_price">
                  ￥ {{ item.exam_money }}
                </div>
                <div class="enroll_list_item_num" style="height: 0.4rem">
                  <!-- <div class="enroll_list_item_enrolled">
                    已报 {{ item.used_count }}
                  </div>
                  <div class="enroll_list_item_surplus">
                    剩余{{ item.remain_count }}个
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty_box" v-else>
        <img class="empty_icon" src="../../assets/image/empty_icon01.png" />
        <div class="empty_name">暂无考试</div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/enroll/enrollAllList.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "EnrollAllList",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "考试列表", // 顶部组件参数设置
      enrollListShow: [], // 报名活动列表数据
      listLoadingKey: false, // 加载数据控制
      page: 1, // 当前页
      perPage: 10, // 每页数据数
      count: 0, // 数据总条数
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let v = this.$route.params.areaId;
    this.gradeId = this.$store.state.checkedGradeId;
    if (v != undefined && v != "") {
      this.areaId = v;
      this.$store.commit("set_checkedAreaId", v);
    } else {
      this.areaId = this.$store.state.checkedAreaId;
    }
    this.page = 1;
    this.getListDataFn();
  },
  methods: {
    // 查询考场列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/examList";
      let data = {
        page: this.page,
        perPage: this.perPage,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.listLoadingKey = false;
            if (this.page == 1) {
              that.enrollListShow = response.exam_list;
            } else {
              that.enrollListShow = that.enrollListShow.concat(
                response.exam_list
              );
            }
            that.count = response.exam_count;
          } else {
            that.listLoadingKey = false;
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          that.listLoadingKey = false;
          console.log(error);
        });
    },
    // 滚动加载
    loadingListFn: function () {
      this.listLoadingKey = true;
      if (this.enrollListShow.length < this.count) {
        this.page = this.page + 1;
        this.getListDataFn();
      }
    },
    // 查看报名详情
    toDetailFn: function (id) {
      this.$router.push({
        name: "EnrollDetail",
        params: { enrollId: id, btnKey: 1 },
      });
    },
  },
};
</script>
