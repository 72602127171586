<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="register_body">
      <div class="register_from_box">
        <div class="register_from_top_tips">*注册人需为报名考生的监护人</div>
        <div class="register_from_item">
          <div class="register_from_item_label">姓名:</div>
          <input
            class="register_from_item_input"
            type="text"
            v-model="userData.name"
            maxlength="6"
          />
        </div>

        <div class="register_from_item" style="justify-content: flex-start">
          <div class="register_from_item_label">证件类型:</div>
          <!-- <select
            class="register_from_item_select"
            name="certificatesType"
            id="certificates_type"
            v-model="userData.identity_type"
          >
            <option
              v-for="(item, index) in certificatesTypeList"
              :key="index"
              :value="item.value"
            >
              <div class="register_from_item_option">{{ item.label }}</div>
            </option>
          </select> -->
          <div class="from_item_select">
            <div class="from_item_select_value" @click="openSelectFn()">
              {{ selectShow }}
            </div>
            <img
              class="from_item_select_icon"
              src="../../assets/image/select_icon01.png"
              @click="openSelectFn()"
            />
            <div
              v-show="selectShowKey"
              class="from_item_select_block"
              @click="closeSelectOptionFn()"
            ></div>
            <div v-show="selectShowKey" class="from_item_select_option_list">
              <div
                class="from_item_select_option"
                v-for="(item, index) in certificatesTypeList"
                :key="index"
                @click="selectOptionFn(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="register_from_item">
          <div class="register_from_item_label">证件号:</div>
          <input
            class="register_from_item_input"
            type="text"
            v-model="userData.identity_number"
          />
        </div>

        <div class="register_from_item">
          <div class="register_from_item_label">手机号:</div>
          <input
            class="register_from_item_input"
            type="number"
            v-model="userData.phone"
          />
        </div>

        <div class="register_from_item">
          <div class="register_from_item_label">验证码:</div>
          <div class="register_from_item_code_box">
            <input
              class="register_from_item_input"
              type="number"
              style="width: 3.2rem"
              v-model="userData.phone_code"
            />
            <div
              :class="[
                getCodeKey
                  ? 'register_from_item_get_code_btn'
                  : 'register_from_item_get_code_btn disabled_send',
              ]"
              @click="getCodeFn()"
            >
              {{ getCodeTime }}
            </div>
          </div>
        </div>

        <div
          :class="[
            agreeKey
              ? 'register_from_send_btn agree_btn'
              : 'register_from_send_btn',
          ]"
          @click="submitDataFn()"
        >
          提交
        </div>

        <div class="register_from_clause_box">
          <div
            :class="[
              agreeKey
                ? 'register_from_clause_check_box agree_icon_box'
                : 'register_from_clause_check_box',
            ]"
            @click="changeAgreeKey()"
          >
            <img
              :class="[
                agreeKey
                  ? 'register_from_clause_check_icon agree_icon'
                  : 'register_from_clause_check_icon',
              ]"
              src="../../assets/image/checked_icon01.png"
            />
          </div>
          <div class="register_from_clause_words">我已经详细阅读并同意</div>
          <div class="register_from_clause" @click="toClauseFn(1)">
            《隐私政策》
          </div>
          <div class="register_from_clause_words">和</div>
          <div class="register_from_clause" @click="toClauseFn(2)">
            《服务条款》
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/register.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "Register",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "完善信息",
      memid: "", // 平台中用户id
      certificatesTypeList: [
        {
          value: 1,
          label: "身份证",
        },
        {
          value: 2,
          label: "其他",
        },
      ], // 证件类型数组
      getCodeTime: "获取验证码", // 获取验证码按钮文字
      getCodeKey: true, // 获取验证码控制
      codeInterval: null,
      agreeKey: false, // 是否同意条款
      userData: {
        name: "",
        identity_type: 1,
        identity_number: "",
        phone: "",
        phone_code: "",
      },
      selectShow: "身份证", // 模拟select显示
      selectShowKey: false, // 下拉显示
      editKey: false,
    };
  },
  created: function () {
    this.memid = this.$store.state.loginUserId;

    let editKey = this.$route.params.editKey;
    if (editKey != undefined && editKey != "") {
      this.$store.commit("set_editKey", editKey);
      this.editKey = this.$store.state.editKey;
    } else {
      this.editKey = this.$store.state.editKey;
    }
    if (this.editKey) {
      this.topTitle = "修改信息";
      this.getDetailDataFn();
    } else {
      this.topTitle = "完善信息";
    }
  },
  methods: {
    // 设置重新获取验证码时间
    setCodeTimeFn: function () {
      let that = this;
      let time = 30;
      this.codeInterval = setInterval(function () {
        if (time > 0) {
          that.getCodeTime = "重新发送(" + time + ")";
          time = time - 1;
        } else {
          that.getCodeTime = "获取验证码";
          that.getCodeKey = true;
          clearInterval(that.codeInterval);
        }
      }, 1000);
    },
    // 打开下拉
    openSelectFn: function () {
      this.selectShowKey = true;
    },
    // 隐藏下拉
    closeSelectOptionFn: function () {
      this.selectShowKey = false;
    },
    // 选择选项
    selectOptionFn: function (item) {
      this.userData.identity_type = item.value;
      this.selectShow = item.label;
      this.selectShowKey = false;
    },
    // 勾选/取消同意
    changeAgreeKey: function () {
      this.agreeKey = !this.agreeKey;
    },
    // 获取验证码
    getCodeFn: function () {
      let that = this;
      if (this.userData.phone == "") {
        Toast({
          message: "请先填写手机号",
          duration: 3000,
        });
        return false;
      } else {
        let telText =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
        if (!telText.test(this.userData.phone)) {
          Toast({
            message: "手机号格式不正确",
          });
          return false;
        }
      }
      if (this.getCodeKey) {
        let url = "/mobile/getPhoneCode";
        let data = {
          phone: that.userData.phone,
        };
        MessageBox.confirm("确认发送短信验证码?")
          .then((action) => {
            console.log(action);
            that.getCodeKey = false;
            that.setCodeTimeFn();
            that.axios
              .post(url, data)
              .then((response) => {
                if (response.code != 0) {
                  that.getCodeKey = true;
                  clearInterval(that.codeInterval);
                  that.getCodeTime == "获取验证码";
                  Toast({
                    message: response.msg,
                    duration: 3000,
                  });
                }
              })
              .catch(function (error) {
                that.getCodeKey = true;
                that.getCodeTime = "获取验证码";
                clearInterval(that.codeInterval);
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    // 提交信息
    submitDataFn: function () {
      let that = this;
      if (this.agreeKey) {
        let url = "/mobile/register";
        let data = this.userData;
        if (data.name == "") {
          Toast({
            message: "请填写姓名",
            duration: 3000,
          });
          return false;
        }

        if (data.identity_number == "") {
          Toast({
            message: "请填写证件号",
            duration: 3000,
          });
          return false;
        } else if (data.identity_type == 1) {
          // 验证身份正则
          let idNumberText =
            /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
          if (!idNumberText.test(data.identity_number)) {
            Toast({
              message: "身份证格式不正确",
            });
            return false;
          } else {
            let date = new Date();
            let str = data.identity_number + "";
            let newStr = new Date(
              str.slice(6, 10) +
                "-" +
                str.slice(10, 12) +
                "-" +
                str.slice(12, 14)
            );
            let yaer = newStr.getFullYear();
            let month = newStr.getMonth();
            let day = newStr.getDate();
            let age = date.getFullYear() - yaer;
            if (
              date.getMonth() < month ||
              (date.getMonth() === month && day > date.getDate())
            ) {
              age = age - 1;
            }
            if (age < 18) {
              Toast({
                message: "未满18岁无法提交",
              });
              return false;
            }
          }
        }

        if (data.phone == "") {
          Toast({
            message: "请填写手机号",
            duration: 3000,
          });
          return false;
        } else {
          let telText =
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
          if (!telText.test(data.phone)) {
            Toast({
              message: "手机号格式不正确",
            });
            return false;
          }
        }

        if (data.phone_code == "") {
          Toast({
            message: "请填写验证码",
            duration: 3000,
          });
          return false;
        }

        this.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              that.$store.commit("set_isRegister", response.is_register);
              clearInterval(that.codeInterval);
              that.$router.replace({
                name: "Home",
              });
            } else {
              Toast({
                message: response.msg,
                duration: 3000,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        MessageBox({
          message:
            "请您阅读《隐私政策》和《服务条款》,点击“同意”开始接受我们的服务",
          confirmButtonText: "同意",
          showCancelButton: true,
        }).then((action) => {
          if (action == "confirm") {
            that.agreeKey = true;
          }
        });
      }
    },
    // 获取用户信息
    getDetailDataFn: function () {
      let that = this;
      let url = "/mobile/personalinfo";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.userData.name = response.userinfo.name;
            that.userData.identity_type = response.userinfo.identity_type;
            that.userData.identity_number = response.userinfo.identity_number;
            that.userData.phone = response.userinfo.phone;
            if (that.userData.identity_type == 1) {
              that.selectShow = "身份证";
            } else {
              that.selectShow = "其他";
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查看条款
    toClauseFn: function (key) {
      this.$router.push({
        name: "Clause",
        params: { showKey: key },
      });
    },
  },
};
</script>
