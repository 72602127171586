<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="enroll_body">
      <div class="enroll_list">
        <div
          class="grade_list_item"
          v-for="(item, index) in listShowData"
          :key="index"
          @click="toCheckExaminationHallFn(item.id)"
        >
          <!-- <img
            class="grade_list_item_img"
            :src="baseAPIUrl + item.image_path"
          /> -->
          <div class="grade_list_item_title">{{ item.title }}</div>
        </div>
      </div>
      <div class="grade_list_bottom_tips">
        (经系统验证，您可以参加的考试项目如上)
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/enroll/gradeList.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "GradeList",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      baseAPIUrl: this.$store.state.baseAPIUrl,
      activeKey: 2,
      topTitle: "考试报名",
      listShowData: [], // 列表数据
    };
  },
  created: function () {
    this.getListDataFn();
  },
  methods: {
    // 查询考级列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/categoryList";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.listShowData = response.category_list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 选择考场
    toCheckExaminationHallFn: function (gradeId) {
      this.$router.push({
        name: "CheckArea",
        params: { gradeId: gradeId },
      });
    },
  },
};
</script>
