<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="admin_apply_body" ref="cardDataBody">
      <div>
        <div class="admin_apply_body_top">
          <img
            class="admin_apply_body_top_img"
            src="../../assets/image/login_img01.png"
          />
          <div class="admin_apply_body_top_right">
            <img
              class="admin_apply_body_top_title_img"
              src="../../assets/image/card_title01.png"
            />
          </div>
        </div>
        <div class="admin_apply_body_card">
          <div class="admin_apply_body_card_name" v-if="isAdmin == 0">
            是否确认成为该考试管理员?
          </div>
          <div class="admin_apply_body_card_name" v-if="isAdmin == 1">
            该考试管理员审核中...
          </div>
          <div class="admin_apply_body_card_name" v-if="isAdmin == 2">
            该考试管理员审核通过！
          </div>
          <img
            class="admin_apply_body_card_img"
            src="../../assets/image/admin_apply01.png"
            v-if="isAdmin == 0"
          />
          <img
            class="admin_apply_body_card_img"
            src="../../assets/image/admin_apply02.png"
            v-if="isAdmin == 1"
          />
          <img
            class="admin_apply_body_card_img"
            src="../../assets/image/admin_apply03.png"
            v-if="isAdmin == 2"
          />
        </div>
      </div>

      <div class="admin_apply_bottom_box">
        <div
          class="admin_apply_bottom_btn_r"
          v-if="isAdmin == 0"
          @click="adminApplyBtn()"
        >
          确认
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/admin/adminApply.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "AdminApply",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "申请管理员",
      wxJSSDKConfig: {}, // 微信JSSDK配置
      examinationId: "", // 考场id
      userid: "",
      isAdmin: 4, // 是否为管理员
    };
  },
  created: function () {
    let token = this.$store.state.adminLoginToken;
    let query = this.$route.query;
    if (query.id) {
      this.examinationId = query.id;
    }
    if (query.state) {
      this.examinationId = query.state;
    }
    if (!token) {
      if (query.code) {
        this.sendlCodeFn(query.code);
      } else {
        this.wxLoginFn();
      }
    } else {
      this.isExaminerFn();
    }
  },
  methods: {
    // 微信授权登录
    wxLoginFn: function () {
      let appid = "wx9271f3e414dea712";
      let redirect_uri = "https://shslqxh.1meeting.com/signin/adminApply";
      let response_type = "code";
      let scope = "snsapi_userinfo";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirect_uri +
        "&response_type=" +
        response_type +
        "&scope=" +
        scope +
        "&state=" +
        this.examinationId +
        "#wechat_redirect";
    },
    // 发送code到后端换取用户信息及token
    sendlCodeFn: function (code) {
      let that = this;
      let url = "/mobile/examiner/examinerLogin";
      let data = {
        code: code,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_adminLoginToken", response.token);
            that.$store.commit("set_loginUserId", response.userid);
            that.userid = response.userid;
            that.isExaminerFn();
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 申请成为管理员
    adminApplyBtn: function () {
      let that = this;
      let url = "/mobile/examiner/becomeAdmin";
      let data = {
        exam_id: this.examinationId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            Toast({
              message: "已提交申请",
              duration: 3000,
            });
            that.isExaminerFn();
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 判断是否为管理员
    isExaminerFn: function () {
      let that = this;
      let url = "/mobile/examiner/isExaminer";
      let data = {
        exam_id: this.examinationId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.isAdmin = response.is_admin;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
