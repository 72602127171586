<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="admin_home_body">
      <div>
        <div class="admin_home_body_top">
          <img
            class="admin_home_body_top_img"
            src="../../assets/image/login_img01.png"
          />
          <div class="admin_home_body_top_right">
            <img
              class="admin_home_body_top_title_img"
              src="../../assets/image/card_title01.png"
            />
          </div>
        </div>
        <div
          class="admin_home_card"
          v-for="(item, index) in examDataList"
          :key="index"
          @click="toExamFn(item.id)"
        >
          <div class="admin_home_top_item">
            <img
              class="admin_home_top_img"
              :src="baseAPIUrl + item.image_path"
              alt="封面"
            />
            <div class="admin_home_top_title">{{ item.title }}</div>
          </div>
          <div class="admin_home_top_item">
            <div class="admin_home_top_time_box">
              <img
                class="admin_home_top_icon"
                src="../../assets/image/time_icon01.png"
              />
              <div class="admin_home_top_time">{{ item.exam_date }}</div>
            </div>
          </div>
          <div class="admin_home_top_item_left">
            <img
              class="admin_home_top_icon"
              src="../../assets/image/address_icon01.png"
              style="height: auto"
            />
            <div class="admin_home_top_address_box">
              <div class="admin_home_top_address">
                <div class="admin_home_top_address_g">
                  {{ item.area_name }}
                </div>
                <div class="admin_home_top_address_b">
                  {{ item.place }}
                </div>
              </div>
              <div class="admin_home_top_address_detail">
                {{ item.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/admin/adminHome.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "AdminHome",
  components: {
    HomeTop,
  },
  data() {
    return {
      baseAPIUrl: this.$store.state.baseAPIUrl,
      topTitle: "签到管理",
      userid: "",
      isAdmin: 0, // 是否为管理员
      examDataList: {}, // 考场数据
    };
  },
  created: function () {
    let token = this.$store.state.adminLoginToken;
    let query = this.$route.query;

    if (!token) {
      if (query.code) {
        this.sendlCodeFn(query.code);
      } else {
        this.wxLoginFn();
      }
    } else {
      this.getExamDataFn();
    }
  },
  methods: {
    // 微信授权登录
    wxLoginFn: function () {
      let appid = "wx9271f3e414dea712";
      let redirect_uri = "https://shslqxh.1meeting.com/signin/adminHome";
      let response_type = "code";
      let scope = "snsapi_userinfo";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirect_uri +
        "&response_type=" +
        response_type +
        "&scope=" +
        scope +
        "&state=" +
        "#wechat_redirect";
    },
    // 发送code到后端换取用户信息及token
    sendlCodeFn: function (code) {
      let that = this;
      let url = "/mobile/examiner/examinerLogin";
      let data = {
        code: code,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_adminLoginToken", response.token);
            that.$store.commit("set_loginUserId", response.userid);
            that.userid = response.userid;
            that.getExamDataFn();
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取考场信息
    getExamDataFn: function () {
      let that = this;
      let url = "/mobile/examiner/examList";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.examDataList = response.list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 签到列表
    toExamFn: function (id) {
      this.$router.push({
        name: "AdminIndex",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
