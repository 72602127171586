<template>
  <div :class="[dialogShow ? 'page_body dialog_show' : 'page_body']">
    <div class="login_body">
      <img
        class="login_body_bg"
        src="https://sh-basketball.oss-cn-shanghai.aliyuncs.com/index/basketball_index.jpg"
      />
      <!-- <div class="login_img_box">
        <img class="login_img" src="../../assets/image/login_img01.png" />
        <div class="login_img_name">上海市青少年篮球技能</div>
        <div class="login_img_name">等级考试报名</div>
      </div> -->
      <div class="login_btn_box">
        <div
          class="login_btn"
          style="margin-bottom: 0.4rem"
          @click="wxLoginFn()"
        >
          <!-- <div class="login_btn" style="margin-bottom: 0.6rem" @click="testFn()"> -->
          登录/login
        </div>
      </div>
      <!-- 提示框 -->
      <div class="dialog_block" v-if="dialogShow">
        <div class="dialog_block_box">
          <img
            class="dialog_block_close"
            src="../../assets/image/close_icon01.png"
            @click="closeDialog()"
          />
          <div class="login_dialog_content">
            <div class="login_dialog_content_title">提示</div>
            <div class="login_dialog_content_title">您还没有完善信息</div>
            <div class="login_dialog_content_tips">
              <img
                class="login_dialog_content_tips_icon"
                src="../../assets/image/warning_icon01.png"
                alt=""
              />
              <div class="login_dialog_content_tips_words">
                完善信息后才能浏览其他内容
              </div>
            </div>
            <div class="login_dialog_content_btn" @click="toRegister()">
              去完善
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/login.css" scoped></style>

<script>
import { Toast } from "mint-ui";
// 引入微信JSSDK
import wx from "weixin-js-sdk";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      dialogShow: false, // 弹框显示
      realUrl: "",
      wxJSSDKConfig: {}, // 微信JSSDK配置
    };
  },
  created: function () {
    let that = this;
    let query = this.$route.query;
    if (query.code) {
      this.sendlCodeFn(query.code);
    }
    this.realUrl = this.$store.state.baseUrl + this.$route.fullPath;
    this.getWXJSSDKConfigFn().then((r) => {
      if (r) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: that.wxJSSDKConfig.appid, // 必填，公众号的唯一标识
          timestamp: that.wxJSSDKConfig.time, // 必填，生成签名的时间戳
          nonceStr: that.wxJSSDKConfig.password, // 必填，生成签名的随机串
          signature: that.wxJSSDKConfig.signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData"], // 必填，需要使用的 JS 接口列表
        });
        wx.ready(function () {
          wx.updateAppMessageShareData({
            title: "上海市篮球协会青少年篮球技能等级考试报名", // 分享标题
            desc: "", // 分享描述
            link: that.$store.state.baseUrl + "/login", // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl:
              that.$store.state.baseAPIUrl + "/image/common/index_logo.png", // 分享图标
            success: function () {
              // 设置成功
            },
          });
        });

        wx.error(function (res) {
          if (
            res.errMsg == "config:invalid signature" &&
            res.realAuthUrl[0] != "" &&
            res.realAuthUrl[0] != undefined
          ) {
            that.realUrl = res.realAuthUrl[0];
          }
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });
      }
    });
  },
  methods: {
    // 前往完善信息
    toRegister: function () {
      this.$router.push({
        name: "Register",
        params: {
          editKey: 1,
        },
      });
    },
    // 显示弹窗
    openDialog: function () {
      this.dialogShow = true;
    },
    // 关闭弹窗
    closeDialog: function () {
      this.dialogShow = false;
      this.$router.replace({
        name: "Home",
      });
    },
    // 微信授权登录
    wxLoginFn: function () {
      let appid = "wx9271f3e414dea712";
      let redirect_uri = "https://shslqxh.1meeting.com/login";
      let response_type = "code";
      let scope = "snsapi_userinfo";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirect_uri +
        "&response_type=" +
        response_type +
        "&scope=" +
        scope +
        "&state=STATE#wechat_redirect";
    },
    // 发送code到后端换取用户信息及token
    sendlCodeFn: function (code) {
      let that = this;
      let url = "/mobile/login";
      let data = {
        code: code,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_loginToken", response.token);
            that.$store.commit("set_loginUserId", response.userid);
            that.$store.commit("set_isRegister", response.is_register);
            if (response.is_register == 1) {
              that.dialogShow = true;
            } else {
              that.$router.replace({
                name: "Home",
              });
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取WXJSSDK配置
    getWXJSSDKConfigFn: function () {
      let that = this;
      let data = {};
      data = {
        url: this.realUrl,
      };
      let url = "/mobile/getjssdkconfig";
      return new Promise(function (resolve, reject) {
        that.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              that.wxJSSDKConfig = response.signpackage;
              resolve(true);
            } else {
              Toast({
                message: response.msg,
                duration: 3000,
              });
              resolve(false);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    testFn: function () {
      let that = this;
      let url = "/mobile/login";
      let data = {
        code: "",
        login_userid: "38",
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_loginToken", response.token);
            that.$store.commit("set_loginUserId", response.userid);
            that.$store.commit("set_isRegister", response.is_register);
            if (response.is_register == 1) {
              that.dialogShow = true;
            } else {
              that.$router.replace({
                name: "Home",
              });
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
