<template>
  <div :class="[dialogShow ? 'page_body dialog_show' : 'page_body']">
    <div class="home_body">
      <!-- 顶部轮播 -->
      <div class="home_swiper_box">
        <mt-swipe :auto="4000" :showIndicators="showIndicators">
          <mt-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img
              v-if="item.image_path != '' && item.image_path != null"
              :src="baseAPIUrl + item.image_path"
              @click="bannerClickFn(item.is_jump, item.linkurl)"
              class="home_swiper_item_img"
            />
            <div v-else class="home_swiper_item"></div>
          </mt-swipe-item>
        </mt-swipe>
      </div>
      <!-- 考试流程引导栏 -->
      <div class="home_examination_process">
        <div class="home_examination_process_title">报考流程</div>
        <div class="home_examination_process_scrollHidden">
          <div class="home_examination_process_listBox">
            <div class="home_examination_process_list">
              <div
                class="home_examination_process_item"
                style="margin-right: 0.2rem"
                @click="toUserListFn()"
              >
                <div class="home_examination_process_item_tip">第一步</div>
                <div class="home_examination_process_item_left">
                  <div class="home_examination_process_item_title">
                    考生信息
                  </div>
                  <div class="home_examination_process_item_link">去录入</div>
                </div>
                <div class="home_examination_process_item_imgBox">
                  <img
                    class="home_examination_process_item_img"
                    src="../../assets/image/examination_process_icon01.png"
                  />
                </div>
              </div>

              <div
                class="home_examination_process_item"
                style="margin-right: 0.2rem"
                @click="toGradeListFn()"
              >
                <div class="home_examination_process_item_tip">第二步</div>
                <div class="home_examination_process_item_left">
                  <div class="home_examination_process_item_title">
                    考试报名
                  </div>
                  <div class="home_examination_process_item_link">去报名</div>
                </div>
                <div class="home_examination_process_item_imgBox">
                  <img
                    class="home_examination_process_item_img"
                    src="../../assets/image/examination_process_icon02.png"
                  />
                </div>
              </div>

              <div
                class="home_examination_process_item"
                style="margin-right: 0.2rem"
                @click="toUserEnrollFn()"
              >
                <div class="home_examination_process_item_tip">第三步</div>
                <div class="home_examination_process_item_left">
                  <div class="home_examination_process_item_title">准考证</div>
                  <div class="home_examination_process_item_link">点击查看</div>
                </div>
                <div
                  class="home_examination_process_item_imgBox"
                  style="height: 0.44rem"
                >
                  <img
                    class="home_examination_process_item_img"
                    src="../../assets/image/examination_process_icon03.png"
                  />
                </div>
              </div>

              <div
                class="home_examination_process_item"
                @click="toUserResultFn()"
              >
                <div class="home_examination_process_item_tip">第四步</div>
                <div class="home_examination_process_item_left">
                  <div class="home_examination_process_item_title">
                    成绩查询
                  </div>
                  <div class="home_examination_process_item_link">点击查看</div>
                </div>
                <div class="home_examination_process_item_imgBox">
                  <img
                    class="home_examination_process_item_img"
                    src="../../assets/image/examination_process_icon04.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 功能模块入口 -->
      <div class="home_label_title"></div>
      <div class="home_btn_list">
        <div
          class="home_btn_list_item"
          style="margin-bottom: 0.2rem"
          @click="toUserListFn()"
        >
          <div class="home_btn_list_item_title">考生信息</div>
          <div class="home_btn_list_item_link">管理考生资料</div>
          <div class="home_btn_list_item_iconBox">
            <img
              class="home_btn_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
            <img
              class="home_btn_list_item_img"
              src="../../assets/image/yingyong_icon01.png"
            />
          </div>
        </div>

        <div
          class="home_btn_list_item"
          style="margin-bottom: 0.2rem"
          @click="toGradeListFn()"
        >
          <div class="home_btn_list_item_title">考试报名</div>
          <div class="home_btn_list_item_link">填写报名信息</div>
          <div class="home_btn_list_item_iconBox">
            <img
              class="home_btn_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
            <img
              class="home_btn_list_item_img"
              src="../../assets/image/yingyong_icon02.png"
            />
          </div>
        </div>

        <div
          class="home_btn_list_item"
          style="margin-bottom: 0.2rem"
          @click="toUserEnrollFn()"
        >
          <div class="home_btn_list_item_title">我的报名</div>
          <div class="home_btn_list_item_link">查看准考证</div>
          <div class="home_btn_list_item_iconBox">
            <img
              class="home_btn_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
            <img
              class="home_btn_list_item_img"
              src="../../assets/image/yingyong_icon03.png"
            />
          </div>
        </div>

        <div
          class="home_btn_list_item"
          style="margin-bottom: 0.2rem"
          @click="toUserResultFn()"
        >
          <div class="home_btn_list_item_title">成绩查询</div>
          <div class="home_btn_list_item_link">查询考试成绩</div>
          <div class="home_btn_list_item_iconBox">
            <img
              class="home_btn_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
            <img
              class="home_btn_list_item_img"
              src="../../assets/image/yingyong_icon04.png"
            />
          </div>
        </div>

        <div class="home_btn_list_item" @click="toCourseListFn()">
          <div class="home_btn_list_item_title">考级示范</div>
          <div class="home_btn_list_item_link">考级示范视频</div>
          <div class="home_btn_list_item_iconBox">
            <img
              class="home_btn_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
            <img
              class="home_btn_list_item_img"
              src="../../assets/image/yingyong_icon05.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="dialog_block" v-if="dialogShow">
      <div class="dialog_block_box">
        <img
          class="dialog_block_close"
          src="../../assets/image/close_icon01.png"
          @click="closeDialog()"
        />
        <div class="login_dialog_content">
          <div class="login_dialog_content_title">提示</div>
          <div class="login_dialog_content_title">{{ dialogTitle }}</div>
          <div class="login_dialog_content_tips">
            <img
              class="login_dialog_content_tips_icon"
              src="../../assets/image/warning_icon01.png"
              alt=""
            />
            <div class="login_dialog_content_tips_words">
              {{ dialogTips }}
            </div>
          </div>
          <div class="login_dialog_content_btn" @click="toRegister()">
            {{ dialogBtn }}
          </div>
        </div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/home/home.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import { Toast } from "mint-ui";

export default {
  name: "Home",
  components: {
    HomeFooter,
  },
  data() {
    return {
      activeKey: 1,
      showIndicators: false, // swipe是否显示indicators
      dialogShow: false, // 弹框显示
      dialogTitle: "", // 弹框提示文本
      dialogTips: "", // 弹框提示文本
      dialogBtn: "", // 弹框按钮文本
      dialogLink: "", // 弹框按钮跳转
      bannerList: [], // banner数据
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    this.getBannerFn();
  },
  methods: {
    // 获取banner信息
    getBannerFn: function () {
      let that = this;
      let url = "/mobile/bannerInfo";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.bannerList = response.banner;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // banner跳转
    bannerClickFn: function (is_jump, linkurl) {
      if (is_jump == 2) {
        window.location.href = linkurl;
      }
    },
    // 考生信息
    toUserListFn: function () {
      let key = this.$store.state.isRegister;
      if (key) {
        this.$router.push({
          name: "UserList",
        });
      } else {
        this.openDialog(1);
      }
    },
    // 考试报名
    toGradeListFn: function () {
      let key = this.$store.state.isRegister;
      if (key) {
        // this.getStuCountFn().then((res) => {
        //   let stu_count = res;
        //   if (stu_count != -1) {
        //     if (stu_count == 0) {
        //       // 没有考生提示去添加
        //       this.openDialog(2);
        //     } else {
        //       this.$router.push({
        //         name: "GradeList",
        //       });
        //     }
        //   }
        // });
        this.$router.push({
          name: "GradeList",
        });
      } else {
        this.openDialog(1);
      }
    },
    // 我的报名
    toUserEnrollFn: function () {
      let key = this.$store.state.isRegister;
      if (key) {
        // this.getStuCountFn().then((res) => {
        //   let stu_count = res;
        //   if (stu_count != -1) {
        //     if (stu_count == 0) {
        //       // 没有考生提示去添加
        //       this.openDialog(2);
        //     } else {
        //       this.$router.push({
        //         name: "UserEnroll",
        //       });
        //     }
        //   }
        // });
        this.$router.push({
          name: "UserEnroll",
        });
      } else {
        this.openDialog(1);
      }
    },
    // 成绩查询
    toUserResultFn: function () {
      let key = this.$store.state.isRegister;
      if (key) {
        // this.getStuCountFn().then((res) => {
        //   let stu_count = res;
        //   if (stu_count != -1) {
        //     if (stu_count == 0) {
        //       // 没有考生提示去添加
        //       this.openDialog(2);
        //     } else {
        //       this.$router.push({
        //         name: "UserResult",
        //       });
        //     }
        //   }
        // });
        this.$router.push({
          name: "UserResult",
        });
      } else {
        this.openDialog(1);
      }
    },
    // 查询是否存在考生信息
    getStuCountFn: function () {
      let that = this;
      let url = "/mobile/checkUserStu";
      let data = {};
      return new Promise(function (resolve, reject) {
        that.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              resolve(response.stu_count);
            } else {
              Toast({
                message: response.msg,
                duration: 3000,
              });
              resolve(-1);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    // 前往完善信息
    toRegister: function () {
      if (this.dialogLink == "Register") {
        this.$router.push({
          name: this.dialogLink,
          params: {
            editKey: 1,
          },
        });
      } else {
        this.$router.push({
          name: this.dialogLink,
        });
      }
    },
    toCourseListFn: function () {
      this.$router.push({
        name: "CourseList",
      });
    },
    // 显示弹窗
    openDialog: function (linkKey) {
      if (linkKey == 1) {
        // 完善信息
        this.dialogTitle = "您还没有完善信息";
        this.dialogTips = "完善信息后才能浏览其他内容";
        this.dialogBtn = "去完善";
        this.dialogLink = "Register";
      } else if (linkKey == 2) {
        // 添加考生
        this.dialogTitle = "您还没有增加考生信息";
        this.dialogTips = "增加考生信息后才能使用该功能";
        this.dialogBtn = "去增加";
        this.dialogLink = "UserList";
      }
      this.dialogShow = true;
    },
    // 关闭弹窗
    closeDialog: function () {
      this.dialogShow = false;
    },
  },
};
</script>
