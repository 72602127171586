<template>
  <div :class="[dialogShow ? 'page_body dialog_show' : 'page_body']">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="user_center_body">
      <div class="user_center_content">
        <!-- 信息框 -->
        <div class="user_center_box top_box" @click="editRegisterFn()">
          <div class="top_box_left">
            <div class="user_center_img_box">
              <img
                v-if="userData.avatarurl == '' || userData.avatarurl == null"
                class="user_center_img"
                src="../../assets/image/base_user_img01.png"
                alt="头像"
              />
              <img
                class="user_center_img"
                v-else
                :src="userData.avatarurl"
                alt="头像"
              />
              <!-- <div class="user_center_tips">上传头像</div> -->
            </div>
            <div class="user_center_name_box">
              <div class="user_center_name">{{ userData.name }}</div>
              <div class="user_center_remarks">{{ userData.describe }}</div>
            </div>
          </div>
          <img
            class="user_center_box_icon"
            src="../../assets/image/to_icon02.png"
          />
        </div>
        <!-- 功能框 -->
        <div class="user_center_box btn_item_box" @click="toUserListFn()">
          <div class="btn_item_title">考生信息</div>
          <img
            class="user_center_box_icon"
            src="../../assets/image/to_icon02.png"
          />
        </div>
      </div>
      <!-- 按钮框 -->
      <div class="user_center_bottom">
        <!-- <div class="user_center_bottom_btn" @click="openService()"> -->
        <!-- <a class="user_center_bottom_btn_a" :href="'tel:' + telNumber"
            >联系客服
          </a> -->
        <!-- 我要咨询
        </div> -->
        <div class="user_center_bottom_btn" @click="logoutBtn()">退出登录</div>
      </div>
    </div>
    <!-- 提示框 -->
    <div class="dialog_block" v-if="dialogShow">
      <div class="dialog_block_box">
        <img
          class="dialog_block_close"
          src="../../assets/image/close_icon01.png"
          @click="closeDialog()"
        />
        <div class="login_dialog_content">
          <div class="login_dialog_content_title">提示</div>
          <div class="login_dialog_content_title">您还没有完善信息</div>
          <div class="login_dialog_content_tips">
            <img
              class="login_dialog_content_tips_icon"
              src="../../assets/image/warning_icon01.png"
              alt=""
            />
            <div class="login_dialog_content_tips_words">
              完善信息后才能浏览其他内容
            </div>
          </div>
          <div class="login_dialog_content_btn" @click="toRegister()">
            去完善
          </div>
        </div>
      </div>
    </div>
    <!-- 提示框 -->
    <div class="dialog_block" v-if="serviceDialogShow">
      <div class="dialog_block_box">
        <img
          class="dialog_block_close"
          src="../../assets/image/close_icon01.png"
          @click="serviceDialogClose()"
        />
        <div class="service_dialog_list">
          <div class="service_dialog_list_item">
            <div class="service_dialog_list_item_title">技术咨询：</div>
            <div class="service_dialog_list_item_val">
              <a class="user_center_bottom_btn_a" :href="'tel:' + telNumber[0]"
                >颜老师-19916556435
                <img
                  class="service_dialog_list_item_icon"
                  src="../../assets/image/tel_icon.png"
                />
              </a>
            </div>
          </div>
          <div class="service_dialog_list_item">
            <div class="service_dialog_list_item_title">报名咨询：</div>
            <div class="service_dialog_list_item_val">
              <div class="service_dialog_list_item_l">浦东新区考点：</div>
              <a class="user_center_bottom_btn_a" :href="'tel:' + telNumber[1]"
                >赵老师-18916678922<img
                  class="service_dialog_list_item_icon"
                  src="../../assets/image/tel_icon.png"
                />
              </a>
            </div>
          </div>
          <div class="service_dialog_list_item">
            <div class="service_dialog_list_item_title">合作咨询：</div>
            <div class="service_dialog_list_item_val">
              <a class="user_center_bottom_btn_a" :href="'tel:' + telNumber[2]"
                >侯老师-15821767959<img
                  class="service_dialog_list_item_icon"
                  src="../../assets/image/tel_icon.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/user/userCenter.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "UserCenter",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 3,
      topTitle: "个人信息",
      userId: "",
      userData: {
        name: "",
        describe: "",
        avatarurl: "",
        phone: "",
      }, // 用户信息
      dialogShow: false, // 弹窗显示
      telNumber: [19916556435, 18916678922, 15821767959], // 客服号码
      serviceDialogShow: false, // 客服显示
    };
  },
  created: function () {
    this.userId = this.$store.state.loginUserId;
    let key = this.$store.state.isRegister;
    if (key) {
      this.getUserDataFn();
    } else {
      this.openDialog();
    }
  },
  methods: {
    // 查询用户数据
    getUserDataFn: function () {
      let that = this;
      let url = "/mobile/personalinfo";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.userData = response.userinfo;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 考生信息
    toUserListFn: function () {
      let key = this.$store.state.isRegister;
      if (key) {
        this.$router.push({
          name: "UserList",
        });
      } else {
        this.openDialog();
      }
    },
    // 前往完善信息
    toRegister: function () {
      this.$router.push({
        name: "Register",
        params: {
          editKey: 1,
        },
      });
    },
    // 显示弹窗
    openDialog: function () {
      this.dialogShow = true;
    },
    // 关闭弹窗
    closeDialog: function () {
      this.dialogShow = false;
    },
    // 打开咨询
    openService: function () {
      this.serviceDialogShow = true;
    },
    // 关闭咨询
    serviceDialogClose: function () {
      this.serviceDialogShow = false;
    },
    // 退出登录
    logoutBtn: function () {
      let that = this;
      MessageBox.confirm("确认退出?")
        .then((action) => {
          console.log(action);
          that.$store.commit("set_loginToken", "");
          that.$store.commit("set_loginUserId", "");
          that.$router.replace({
            name: "Login",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 修改用户信息
    editRegisterFn: function () {
      this.$router.push({
        name: "Register",
        params: {
          editKey: 2,
        },
      });
    },
  },
};
</script>
