<template>
  <div class="home_bottom">
    <div
      :class="[
        activeKey == 1
          ? 'home_bottom_item home_bottom_item_isActive'
          : 'home_bottom_item',
      ]"
      @click="toHome()"
    >
      <img
        v-if="activeKey == 1"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon01.png"
        alt="首页"
      />
      <img
        v-if="activeKey != 1"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon02.png"
        alt="首页"
      />
      <div class="home_bottom_item_name">首页</div>
    </div>
    <!-- <div
      :class="[
        activeKey == 2
          ? 'home_bottom_item home_bottom_item_isActive'
          : 'home_bottom_item',
      ]"
      @click="toGrade()"
    >
      <img
        v-if="activeKey == 2"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon03.png"
        alt="考试列表"
      />
      <img
        v-if="activeKey != 2"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon04.png"
        alt="考试列表"
      />
      <div class="home_bottom_item_name">考试列表</div>
    </div> -->
    <div
      :class="[
        activeKey == 3
          ? 'home_bottom_item home_bottom_item_isActive'
          : 'home_bottom_item',
      ]"
      @click="toUserCenter()"
    >
      <img
        v-if="activeKey == 3"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon05.png"
        alt="考试列表"
      />
      <img
        v-if="activeKey != 3"
        class="home_bottom_item_img"
        src="../assets/image/home_footer_icon06.png"
        alt="考试列表"
      />
      <div class="home_bottom_item_name">个人信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  props: {
    activeKey: Number,
  },
  data() {
    return {};
  },
  created: function () {},
  methods: {
    // 跳转到首页
    toHome: function () {
      this.$router.push({
        name: "Home",
      });
    },
    // 跳转到考试
    toGrade: function () {
      this.$router.push({
        name: "EnrollAllList",
      });
    },
    // 跳转到个人中心
    toUserCenter: function () {
      this.$router.push({
        name: "UserCenter",
      });
    },
  },
};
</script>
