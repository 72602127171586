<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="notice_detail_box">
      <p style="text-align: center; line-height: 150%">
        <strong
          ><span style="font-size: 24px; line-height: 150%">2022</span></strong
        ><strong
          ><span style="font-size: 24px; line-height: 150%; font-family: 宋体"
            >年度上海市篮球协会青少年篮球技能等级考试</span
          ></strong
        >
      </p>
      <p style="text-align: center; line-height: 150%">
        <strong
          ><span style="font-size: 24px; line-height: 150%; font-family: 宋体"
            >考生须知</span
          ></strong
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="text-indent: 28px; line-height: 150%">
        <span style="font-family: 宋体">参加</span>2022<span
          style="font-family: 宋体"
          >年度上海市篮球协会青少年篮球技能等级考试的考生须在报名前认真阅读以下内容，了解相关规定和要求，并认真执行。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">一、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">报考须知</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考级规定：未定级的考生在初次报考时，可自选从一级或二级起报，三级（含）起应逐级报考，不得跨级别报考。举例：初次报考：一级或二级；报考三级：须有二级证书，以此类推。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">凡符合初次报考年龄为</span>6<span
          style="font-family: 宋体"
          >周岁及以上，符合考试安全健康规定的青少年，均可报名参加本考试，考试不限性别。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">二、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">考试费用</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考级费用：篮球技能等级一级至三级考试费用，均为</span
        >298<span style="font-family: 宋体">元</span>/<span
          style="font-family: 宋体"
          >人（含证书制作及保险费用）。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >等级证书：通过考核考生，可获得由上海市篮球协会统一发放的篮球技能等级证书。</span
        >
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >费用缴纳：考生可根据报考须知选择考试等级，通过官方报名渠道按规定缴纳考试费用。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >报名截止日期前，考生可根据自身情况申请退费。</span
        >
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >报名截止日期后，考生个人原因造成的错报、漏报或缺考等情况，考试费用不予退还。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">三、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">考试报名</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生应在监护人陪同下，通过上海市篮球协会官方报名通道报名考级。考生或监护人需在线完成考试账号注册、考生信息填报、考试报名。缴纳考试费用后，获得电子版考试准考证。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">四、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">防疫防控</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生即日起持续关注上海市疫情防控最新要求，并按要求提前做好防控准备。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >来自外省市地区的考生，建议出发前与上海市防疫部门联系，确定当前防疫防控政策，了解到达后的健康管理措施。请考生合理安排行程，完成防疫防控健康管理措施后方可报到并参加考试。</span
        >
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">考生需提前通过“随申办市民云”</span
        >APP<span style="font-family: 宋体"
          >申领“随申码”，按上海市防疫防控要求进行常规核酸检测，并确保其为绿码。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">考生应自行做好考前</span>14<span
          style="font-family: 宋体"
          >天自主健康监测。如出现发热、咳嗽、咽痛等症状，须及时就医排除新冠肺炎疫情风险。</span
        >
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生及陪同监护人进入考点、考场，须自觉接受体温检测，出示“随申码”绿码，扫描“场所码”，全程佩戴口罩（核验身份和考试过程中除外），等候期间保持一米间距，服从现场工作人员管理及防疫工作安排。</span
        >
      </p>
      <p style="line-height: 150%">
        6.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生考试结束后应迅速离开考点，减少人员聚集。</span
        >
      </p>
      <p style="line-height: 150%">
        7.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >对提供虚假防疫证明、身体健康状况信息，隐瞒行程、病情，瞒报、漏报健康状况，并造成新冠肺炎疫情传播风险的考生及其监护人，将追究相应法律责任。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">五、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">报到及考试</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">报到时间：考生应于考试时间至少</span
        >30<span style="font-family: 宋体">分钟前到达考场。考试开始后</span
        >10<span style="font-family: 宋体"
          >分钟无法到达考场，视为自愿放弃考试资格。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >报到检录：考生应于考试当天，携带以下相关材料到考场检录区进行报到。经现场工作人员查验防疫信息、报到材料后，发放考生号码牌。以下材料缺一或逾期均不予办理报到手续，不予安排考试。</span
        >
      </p>
      <p style="line-height: 150%">
        <span style="font-family: 宋体">（</span>1<span
          style="font-family: 宋体"
          >）</span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >“随申码”绿码（包含规定时间内的核酸检测阴性结果）；</span
        >
      </p>
      <p style="line-height: 150%">
        <span style="font-family: 宋体">（</span>2<span
          style="font-family: 宋体"
          >）</span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">“场所码”无异常；</span>
      </p>
      <p style="line-height: 150%">
        <span style="font-family: 宋体">（</span>3<span
          style="font-family: 宋体"
          >）</span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">本人身份证原件；</span>
      </p>
      <p style="line-height: 150%">
        <span style="font-family: 宋体">（</span>4<span
          style="font-family: 宋体"
          >）</span
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">电子版准考证。</span>
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >候考：考生应在工作人员指导下，黏贴考生号码牌（考试全程佩戴，不得摘下或损坏），并根据现场引导等待和入场考试。考生须听从现场管理，遵守纪律，保持安静，不得随意跑动或掉队，以免漏考；不得擅自离开候考区域或进入考试场地。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考试：考生跟随工作人员至第一项考试区域，期间需听从考官管理，依次进行该项内容的考试，考生每考完一项依次到下一个项目区域排队候考，直至完成所有考试项目。考试期间不得随意交流，如有问题或不适需举手向考官示意。</span
        >
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >离场：考生完成所有考试项目以后，回到考生候场区由工作人员带队前往家长休息区，并由家长认领后离开。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">六、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">成绩和证书</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">考生可关注上海市篮球协会官方平台</span
        >(<span style="font-family: 宋体">官方网站</span>www.shslqxh.com<span
          style="font-family: 宋体"
          >、官方微信公众号“上海市篮球协会”</span
        >) <span style="font-family: 宋体">查询成绩。</span>
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">考试总分</span>59<span
          style="font-family: 宋体"
          >分及以下，为不合格；</span
        >60<span style="font-family: 宋体">分（含</span>60<span
          style="font-family: 宋体"
          >分）至</span
        >75<span style="font-family: 宋体">分，为合格；</span>76<span
          style="font-family: 宋体"
          >分（含</span
        >76<span style="font-family: 宋体">分）至</span>90<span
          style="font-family: 宋体"
          >分，为良好；</span
        >91<span style="font-family: 宋体">分（含</span>91<span
          style="font-family: 宋体"
          >分）至</span
        >100<span style="font-family: 宋体">分，为优秀。考试总分</span>60<span
          style="font-family: 宋体"
          >分及以上，视为通过考核。</span
        >
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >通过考核的考生，可在上海市篮球协会官方平台下载电子成绩证书，以及联系考试所在考点获取纸质版成绩证书。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >纸质证书以邮寄方式由上海市篮球协会统一发出到对应考点，考生凭准考证到考点领取证书。</span
        >
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >上海市篮球协会是本考级的唯一发证单位，官方纸质证书含“上海市篮球协会”红印公章，官方电子版证书含“上海市篮球协会”电子公章。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">七、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">考试安全</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >参加考试前，考生需充分了解此项考试的形式和特点，且结合自身身体情况，合理预估活动风险，最终决定是否参加此项考试。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >凡患有传染性疾病、心脏病、高血压以及其它限制参加体育运动疾病的考生，应如实报告，且不得参加考试。</span
        >
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">未满</span>18<span
          style="font-family: 宋体"
          >周岁的考生需有监护人陪同前往。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体">考生须穿着篮球运动服和篮球鞋。</span>
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生不得佩戴首饰和具有安全隐患的饰品。</span
        >
      </p>
      <p style="line-height: 150%">
        6.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >在考试过程中，考生应增强自我保护意识，妥善采取安全保护措施，避免危险的发生。</span
        >
      </p>
      <p style="line-height: 150%">
        7.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >本次考试为具有一定风险的文体活动，考生应自愿参加。考生因其他参加者的行为受到损害的，受害人不得请求其他参加者承担侵权责任，但是其他参加者对损害的发生有故意或者重大过失的除外。</span
        >
      </p>
      <p style="line-height: 150%">
        8.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考试过程中考生要尽到注意义务，遵守规则，避免对同伴人身造成损害，若对损害的发生有故意或者重大过失，则需承担赔偿责任。</span
        >
      </p>
      <p style="line-height: 150%">
        9.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生和陪同监护人须遵守《上海市篮球协会青少年篮球技能等级工作手册》管理办法。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">
        <strong
          ><span style="font-family: 宋体">八、</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong
        ><strong><span style="font-family: 宋体">考试纪律</span></strong>
      </p>
      <p style="line-height: 150%">
        1.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生的报名资料必须真实，如有虚假信息，一经查实，将取消该考生的考试资格、考试成绩。</span
        >
      </p>
      <p style="line-height: 150%">
        2.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考生应自觉服从监考人员管理，不得以任何理由妨碍监考人员进行正常工作。对扰乱考场秩序、妨碍考试进行的考生将作取消考试资格处理。</span
        >
      </p>
      <p style="line-height: 150%">
        3.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考试过程中不得中途退场，如因身体不适要求中途退场，须报备现场监考人员。</span
        >
      </p>
      <p style="line-height: 150%">
        4.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >如考生在考试过程中发生违规行为，由考试组织委员会和考点工作组对违规行为进行研判与处理。</span
        >
      </p>
      <p style="line-height: 150%">
        5.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >如考生对考试成绩存有疑问，可致电上海市篮球协会申请复议。</span
        >
      </p>
      <p style="line-height: 150%">
        6.&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-family: 宋体"
          >考点现场将设有录音录像设备，由上海市篮球协会进行留存，并作为考试档案和成绩复议的依据。</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="text-indent: 28px; line-height: 150%">
        <span style="font-family: 宋体"
          >请广大考生牢记以上注意事项，共同维护考试秩序，注意个人防护，以健康的身体状态、良好的心理状态参加考试。预祝广大考生取得理想成绩！</span
        >
      </p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="line-height: 150%">&nbsp;</p>
      <p style="text-align: right; line-height: 150%">
        <span style="font-family: 宋体">上海市篮球协会</span>
      </p>
      <p style="text-align: right; line-height: 150%">
        2022<span style="font-family: 宋体">年</span>8<span
          style="font-family: 宋体"
          >月</span
        >16<span style="font-family: 宋体">日</span>
      </p>
      <p>
        <span
          style="font-size:14px;line-height: 150%;font-family:&#39;Calibri&#39;,sans-serif"
          ><br />
        </span>
      </p>
      <p style="line-height: 150%">&nbsp;</p>

      <div v-for="(item, index) in userList" :key="index">
        <p style="text-align: center; line-height: 150%">
          <span style="font-size: 29px; line-height: 150%; font-family: 宋体"
            >承诺书</span
          >
        </p>
        <p style="line-height: 150%">
          <span style="font-family: 宋体">上海市篮球协会：</span>
        </p>
        <p style="line-height: 150%">&nbsp;</p>
        <p style="text-indent: 56px; line-height: 150%">
          <span style="font-family: 宋体">考生</span>{{ item.stu_name
          }}<span style="font-family: 宋体">（证件号：</span
          >{{ item.stu_identity_number
          }}<span style="font-family: 宋体">）监护人</span>{{ item.name
          }}<span style="font-family: 宋体">（证件号：</span
          >{{ item.identity_number
          }}<span style="font-family: 宋体">）已充分阅读并理解《</span>2022<span
            style="font-family: 宋体"
            >年度上海市篮球协会青少年篮球技能等级考试考生须知》，承诺考生符合报名资质和健康要求，并同意遵守相关考试规范及防疫政策，如因自身原因导致无法参加考试或考试不合格或发生任何安全事故损害，自行承担相应责任。</span
          >
        </p>
        <p style="text-indent: 56px; line-height: 150%">&nbsp;</p>
        <p style="text-indent: 56px; line-height: 150%">&nbsp;</p>
        <p style="text-align: right; line-height: 150%">
          <span style="font-family: 宋体">考生监护人签字：{{ item.name }}</span
          >&nbsp;&nbsp;
        </p>
        <p style="text-align: right; line-height: 150%">
          <span style="font-family: 宋体">日期：{{ nowDate }}</span
          >&nbsp;&nbsp;
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/noticeDetail.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "NoticeDetail",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "考生须知",
      baseAPIUrl: this.$store.state.baseAPIUrl,
      userId: "",
      userList: [],
      nowDate: "",
    };
  },
  created: function () {
    this.userId = this.$store.state.checkedUserId;
    this.getUserListFn();
    let newStr = new Date();
    let yaer = newStr.getFullYear();
    let month = newStr.getMonth();
    let day = newStr.getDate();
    this.nowDate = yaer + "年" + (month + 1) + "月" + day + "日";
  },
  methods: {
    // 获取考生信息
    getUserListFn: function () {
      let that = this;
      let url = "/mobile/stuUserInfo";
      let data = {
        stu_ids: this.userId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            let list = [];
            response.list.forEach(function (item) {
              let obj = {
                name: item.name,
                identity_number: item.identity_number,
                stu_name: item.stu_name,
                stu_identity_number: item.stu_identity_number,
              };
              list.push(obj);
            });
            that.userList = list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
