<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="clause_box" v-if="showKey == 1">
      <div class="act_desc">
        <p style="text-align: center; background: white">
          <strong
            ><span style="font-size: 28px; color: #3b99fc"
              >隐私政策</span
            ></strong
          >
        </p>
        <p style="text-align: left; text-indent: 32px; background: white">
          <strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >&nbsp;</span
            ></strong
          >
        </p>
        <p style="text-align: right; line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >生效时间：2022年8月1日</span
          >
        </p>
        <p style="text-align: right; line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >更新时间：2022年8月1日</span
          >
        </p>
        <p style="text-align: left; text-indent: 32px; background: white">
          <strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >&nbsp;</span
            ></strong
          >
        </p>
        <p style="text-align: left; text-indent: 32px; background: white">
          <strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >本隐私政策第</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >8</span
            ></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >条专门对未成年人的个人信息保护做了规定，如您是未成年人用户的法定监护人，请您仔细阅读关于未成年人信息保护的条款并选择是否同意本隐私政策，同时请您教育引导未成年人增强个人信息保护意识和能力，如未经您的同意，不向任何产品和服务提供者提供任何个人信息；如您是未成年人用户，请在征得您法定监护人同意的前提下使用我们的产品和服务或向我们提供信息。为符合《儿童个人信息网络保护规定》，我们对未满</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >14</span
            ></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >周岁儿童的个人信息保护措施做了进一步规定。</span
            ></strong
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <span style="font-size: 13px; color: #666666"
            >本隐私政策将帮助您了解以下内容：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >、如何收集和使用您的个人信息</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >、如何共享、转让、公开披露您的个人信息</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >、如何保存您的个人信息</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4</span
          ><span style="font-size: 13px; color: #666666">、</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Cookie</span
          ><span style="font-size: 13px; color: #666666">的使用</span>
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5</span
          ><span style="font-size: 13px; color: #666666"
            >、个人敏感信息提示</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;
          </span>
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6</span
          ><span style="font-size: 13px; color: #666666">、个人信息安全</span>
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >7</span
          ><span style="font-size: 13px; color: #666666"
            >、个人信息安全事件的处置</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >8</span
          ><span style="font-size: 13px; color: #666666"
            >、未成年人的个人信息保护</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >9</span
          ><span style="font-size: 13px; color: #666666"
            >、您的个人信息权利</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >10</span
          ><span style="font-size: 13px; color: #666666"
            >、本隐私政策的变更</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >11</span
          ><span style="font-size: 13px; color: #666666">、如何联系我们</span>
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >1</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、如何收集和使用您的个人信息</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >您理解并同意：</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本隐私政策或通过弹窗或页面提示等方式向您说明对应个人信息的收集目的、范围及使用方式，并在征得您明示同意后收集、使用您的个人信息。</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666">个人信息</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666">个人敏感信息</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >以上个人信息和个人敏感信息包含的内容分别与《个人信息安全规范》最新生效版本的附录</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >A</span
          ><span style="font-size: 13px; color: #666666">（资料性附录）</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >-</span
          ><span style="font-size: 13px; color: #666666"
            >个人信息示例和附录</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >B</span
          ><span style="font-size: 13px; color: #666666">（资料性附录）</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >-</span
          ><span style="font-size: 13px; color: #666666"
            >个人敏感信息判定的内容一致。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333"
              >（一）收集和使用您个人信息的基本功能</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >我们的服务包括一些基本功能，这些功能包括实现网上报名所必须的功能，改进我们的服务所必须的功能及保障交易安全所必须的功能。我们需要收集、保存和使用下列与您有关的个人信息才能实现该等基本功能。如果您不提供相关信息，您将无法享受我们提供的服务，请您知晓，必要时，我们可能会将您提供的身份信息向发证机关进行核验，以保障人证合一，账户及个人信息安全，各项基本功能及其所需的个人信息类型如下：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）注册成为我们的用户并登录</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >•</span
          ><span style="font-size: 13px; color: #666666"
            >如果您注册成为我们的用户，您需要创建用户名和密码，便于我们为您提供报名等服务，并提供</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >姓名、性别、国家</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >/</span
            ></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >地区、证件类型、证件号码、证件有效期、出生日期，以及与身份信息对应的且可通话联络的唯一手机号码、短信息验证。</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）为您提供报名服务</span
          >
        </p>
        <p>
          <span
            style="font-size:13px;font-family: &#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666"
            >如您选择报名服务，需要提供该实际报名人的个人信息。一旦完成报名行为，即视为该实际订购人亦同意接受本
            《隐私政策》。因此，</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >您在为其他人办理报名或服务之前，对于监护人，要求其填写与未成年人的关系，并承诺信息属实，对于机构，要求其收集监护人同意其代为在网站报名篮协考试的承诺书。我们有权要求您提供相应的身份关系证明及授权同意证明文件。</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）保障您的交易安全</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >为提高您使用我们及我们合作伙伴提供服务的安全性，确保操作环境安全与识别注册账号异常状态，更好地保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反中华人民共和国法律法规，我们可能使用或整合您的</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >用户信息、交易信息、设备信息、位置信息、日志信息以及我们合作伙伴取得您授权或依据法律共享的信息</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333"
              >（二）收集和使用您个人信息的扩展功能</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666"
            >为使您享受的服务更便捷或使您的体验更丰富，我们提供了以下扩展功能，下列功能可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用第（一）条所列明的基本功能。这些扩展功能及其所需的个人信息类型如下：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）为方便您使用产品或服务</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >a</span
          ><span style="font-size: 13px; color: #666666">）</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >设备信息</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息，可用于识别您的设备（例如</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >设备型号、操作系统版本、设备设置、唯一设备标识符，设备</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >MAC</span
            ></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >地址，应用安装列表</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >等软硬件特征信息），以向您提供安全保障。请您理解，当您将应用移动切换到设备后台运行时，由于网络异常或系统原因，您此前使用应用而产生的信息收集行为可能无法立即停止，从而导致短暂的后台信息收集行为。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >b</span
          ><span style="font-size: 13px; color: #666666">）</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >日志信息</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >：当您使用我们的网站或客户端提供的产品或服务时，为向您提供便捷化的服务，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。用于运营分析，性能安全漏洞的上报。例如您的搜索等。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >请您注意，单独的设备信息、日志信息、位置信息是无法识别特定自然人身份的。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息按照本隐私政策对其进行处理与保护。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）为您提供您购买或使用的特定的产品或服务</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666">为</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >展示您账户的订单信息</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666">当</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >您与我们联系</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >时，我们可能会保存您的通信</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >/</span
          ><span style="font-size: 13px; color: #666666"
            >通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333"
              >（三）征得授权同意的例外</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >根据中华人民共和国相关法律法规、国家标准，以下情形中收集您的个人信息无需征得您的授权同意：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1)</span
          ><span style="font-size: 13px; color: #666666"
            >与我们履行法律法规规定的义务相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）与中华人民共和国国家安全、国防安全直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）与公共安全、公共卫生、重大公共利益直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4</span
          ><span style="font-size: 13px; color: #666666"
            >）与刑事侦查、起诉、审判和判决执行等直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5</span
          ><span style="font-size: 13px; color: #666666"
            >）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6</span
          ><span style="font-size: 13px; color: #666666"
            >）所收集的个人信息是您自行向社会公众公开的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >7</span
          ><span style="font-size: 13px; color: #666666"
            >）根据您要求签订和履行合同所必需的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >8</span
          ><span style="font-size: 13px; color: #666666"
            >）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >9</span
          ><span style="font-size: 13px; color: #666666"
            >）维护所提供产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >10</span
          ><span style="font-size: 13px; color: #666666"
            >）作为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >2</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、如何共享、转让、公开披露您的个人信息</span
            ></strong
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333">（</span></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >1</span
            ></strong
          ><strong
            ><span style="font-size: 13px; color: #333333">）共享</span></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >根据您的选择与授权或符合</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666"
            >共享、转让、公开披露个人信息时事先征得授权同意的例外</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >中的情形，我们可能会向第三方共享您的订单信息、账户信息、联系方式以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >在某些情形下我们也会受委托处理您的个人信息，</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >我们作为受托方</span
            ></strong
          ><span style="font-size: 13px; color: #666666">的情形通常包括：</span>
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666"
            >委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >/</span
          ><span style="font-size: 13px; color: #666666"
            >或服务的用户群提供促销推广的服务。在您同意之后，我们会向这些合作伙伴提供有关其推广覆盖面和有效性的信息，而</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >例如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的产品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >对于我们与之共享个人信息的公司、组织，我们会与其签署严格的保密协定，要求他们按照本隐私政策以及我们其他相关的保密和安全措施来处理个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途，如要改变个人信息的处理目的，将再次征求您的授权同意。如我们与第三方分享儿童的个人信息，我们将进行安全评估并采用加密、匿名化、去标识处理等方法保障儿童的信息安全。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333">（</span></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >2</span
            ></strong
          ><strong
            ><span style="font-size: 13px; color: #333333">）转让</span></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">除符合</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666"
            >共享、转让、公开披露个人信息时事先征得授权同意的例外</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >中的情形外，我们不会将您的个人信息转让给任何公司、组织和个人。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >在涉及收购、兼并、重组、破产或类似的交易或情形时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，如变更个人信息使用目的的，我们将要求该公司、组织重新向您征求授权同意。若破产而无承接方的，在符合法律规定的情况下，我们会对您的个人信息做删除处理。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333">（</span></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >3</span
            ></strong
          ><strong
            ><span style="font-size: 13px; color: #333333"
              >）公开披露</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >我们仅会在以下情形，公开披露您的个人信息：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666"
            >在您明确同意或主动选择情况下，我们可能会公开披露您所指定的个人信息；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >• </span
          ><span style="font-size: 13px; color: #666666"
            >根据中华人民共和国法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span style="font-size: 13px; color: #333333">（</span></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >4</span
            ></strong
          ><strong
            ><span style="font-size: 13px; color: #333333"
              >）共享、转让、公开披露个人信息时事先征得授权同意的例外</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >根据中华人民共和国相关法律法规、国家标准，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）与我们履行法律法规规定的义务相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）与国家安全、国防安全直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）与公共安全、公共卫生、重大公共利益直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4</span
          ><span style="font-size: 13px; color: #666666"
            >）与刑事侦查、起诉、审判和判决执行等直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5</span
          ><span style="font-size: 13px; color: #666666"
            >）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6</span
          ><span style="font-size: 13px; color: #666666"
            >）所涉及的个人信息是您自行向社会公众公开的个人信息；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >7</span
          ><span style="font-size: 13px; color: #666666"
            >）根据您要求签订和履行合同所必需的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >8</span
          ><span style="font-size: 13px; color: #666666"
            >）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >9</span
          ><span style="font-size: 13px; color: #666666"
            >）维护我们所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >根据中华人民共和国法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >3</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、如何保存您的个人信息</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >即使您从中国大陆以外地区或国家访问我们网站，请您注意，您的信息将</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >存储于中国大陆，并且在中国大陆进行处理。</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >一般而言，我们仅为实现上述目的所必需的最短时间保留您的个人信息。在超出保存期限后，我们会对您的个人信息进行删除或者匿名化处理。</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >4</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc">、</span></strong
          ><strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >Cookie</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc">的使用</span></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Cookie</span
          ><span style="font-size: 13px; color: #666666"
            >是网页服务器放在您访问设备上的文本文件，会帮助您在后续访问时调用信息，简化记录您填写个人信息的流程。您有权接受或拒绝</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Cookie</span
          ><span style="font-size: 13px; color: #666666"
            >，如果浏览器自动接收</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Cookie</span
          ><span style="font-size: 13px; color: #666666"
            >，您可以根据自己的需要修改浏览器的设置以拒绝</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Cookie</span
          ><span style="font-size: 13px; color: #666666">。</span>
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >5</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、个人敏感信息提示</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >在此提醒您，您在使用报名系统为您提供的产品及服务中所上传或发布的内容和信息可能涉及您的个人敏感信息。包括但不限于</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >证件号码、个人生物识别信息（人脸信息、指纹信息）、行程信息、</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >14
            </span></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >岁以下（含）儿童的个人信息。因此，您需要在使用我们为您提供的产品或服务前谨慎考虑。您同意这些个人敏感信息将按照本隐私政策阐明的目的和方式来进行处理。</span
            ></strong
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >6</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、个人信息安全</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）我们非常重视信息安全，成立了专门的负责团队。我们努力为您提供信息保护，采取了合适的管理、技术以及物理安全措施，参照国内外信息安全标准及最佳实践建立了与业务发展相适应的信息安全保障体系。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）我们从数据的生命周期角度出发，在数据收集、存储、显示、处理、使用、销毁等各个环节建立了安全防护措施，根据信息敏感程度的级别采取不同的控制措施，包括但不限于访问控制、</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >SSL</span
          ><span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >Secure Socket Layer</span
          ><span style="font-size: 13px; color: #666666"
            >）加密传输进行加密存储、敏感信息脱敏显示等。我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，使用加密技术提高个人信息的安全性，使用受信赖的保护机制防止个人信息遭到恶意攻击，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）我们部署了访问控制机制，尽力确保只有授权人员才可访问个人信息。我们对可能接触到您信息的工作人员也采取了严格管理，可监控他们的操作情况，对于数据访问、内外部传输使用、脱敏、解密等重要操作建立了审批机制，对于接触考生信息的工作人员，请与其签署保密协议或要求其签署保密承诺。与此同时，我们还定期对工作人员进行信息安全培训，要求工作人员在日常工作中形成良好操作习惯，提升数据保护意识。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4</span
          ><span style="font-size: 13px; color: #666666"
            >）尽管有前述的安全措施，但同时也请您理解在网络上不存在</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666">完善的安全措施</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄露、损毁或丢失。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5</span
          ><span style="font-size: 13px; color: #666666"
            >）您的账户均有安全保护功能，</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >请妥善保管您的账号及密码信息，切勿将密码告知他人或其他网站，如果您发现自己的个人信息泄露，特别是您的账号和密码发生泄露，请您立即与我们的客服联系，以便我们采取相应的措施。</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6</span
          ><span style="font-size: 13px; color: #666666"
            >）请您及时保存或备份您的文字、图片等其他信息，您需理解并接受，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >7</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、个人信息安全事件的处置</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >在不幸发生个人信息安全事件后，我们会启动应急预案，阻止安全事件扩大，并将按照中华人民共和国法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >8</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、<a>未成年人的个人信息保护</a></span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >按照中华人民共和国相关法律规定，未满</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >18</span
          ><span style="font-size: 13px; color: #666666"
            >周岁为未成年人，不满</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
          >
            14 </span
          ><span style="font-size: 13px; color: #666666"
            >周岁的未成年人为儿童。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >目前本网站不支持未满</span
            ></strong
          ><strong
            ><span
              style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#333333"
              >18</span
            ></strong
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >周岁的未成年人自行注册账户。</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >如您是未成年人的监护人，我们提示您正确履行监护职责，保护未成年人个人信息安全。若您所监护的未成年人使用我们的服务的，您应予以正确引导和监护。</span
          ><strong
            ><span style="font-size: 13px; font-family: 等线; color: #333333"
              >如您是未成年人，您需要通过您的监护人注册账户或在取得您监护人同意的情形下由授权机构注册相关账户并为您提供本网站服务。<span
                style="background: lime; background: lime"
                >如您为授权机构用户，您在向我们提供未成年人信息时应当取得父母或监护人的同意。</span
              ></span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >对于经父母或监护人同意使用我们的产品或服务而收集未成年人，尤其是儿童的个人信息情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得父母或监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >对于儿童个人信息，我们会进一步采取以下措施：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）当您作为监护人为被监护的儿童选择使用网站相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >/</span
          ><span style="font-size: 13px; color: #666666"
            >或服务，您应正确引导并予以监护。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的要求。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >我们指定了专人负责儿童个人信息保护事宜，如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请通过本隐私政策中列明的联系方式联系我们。我们会及时为您提供帮助。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >9</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、您的个人信息权利</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息的权利，法律法规另有规定的除外。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，通常情况下，我们将在收到您反馈并验证您的身份后的</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >15</span
          ><span style="font-size: 13px; color: #666666"
            >个工作日内受理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666">）您可以随时登录</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666">个人中心</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >，常用信息等功能模块以访问、更正、删除您的账户信息，包括个人资料、权限设置、安全设置，常用联系人、密码等。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）您可以通过关闭设备功能来撤回您的授权。您也可以通过注销账户的方式撤回我们继续收集您个人信息的全部授权。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）在以下情形中，按照法律法规、国家标准的要求，我们将无法响应您的请求：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1) </span
          ><span style="font-size: 13px; color: #666666"
            >与我们履行法律法规规定的义务相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2) </span
          ><span style="font-size: 13px; color: #666666"
            >与国家安全、国防安全直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3) </span
          ><span style="font-size: 13px; color: #666666"
            >与公共安全、公共卫生、重大公共利益直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4) </span
          ><span style="font-size: 13px; color: #666666"
            >与刑事侦查、起诉、审判和执行判决等直接相关的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5) </span
          ><span style="font-size: 13px; color: #666666"
            >有充分证据表明您存在主观恶意或滥用权利的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6) </span
          ><span style="font-size: 13px; color: #666666"
            >出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >7) </span
          ><span style="font-size: 13px; color: #666666"
            >响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >8) </span
          ><span style="font-size: 13px; color: #666666">涉及商业秘密的。</span>
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >10</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、本隐私政策的变更</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <strong
            ><span style="font-size: 13px; color: #666666"
              >本隐私政策的内容可能会不时更新，以适应法律、技术或商业的发展。更新后的隐私政策将在网站上公布，重大变更将以网站公告、用户通知等合适的形式告知用户。</span
            ></strong
          ><span style="font-size: 13px; color: #666666"
            >本隐私政策所指的重大变更包括：</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >1</span
          ><span style="font-size: 13px; color: #666666"
            >）我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >2</span
          ><span style="font-size: 13px; color: #666666"
            >）我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >3</span
          ><span style="font-size: 13px; color: #666666"
            >）个人信息共享、转让或公开披露的主要对象发生变化；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >4</span
          ><span style="font-size: 13px; color: #666666"
            >）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >5</span
          ><span style="font-size: 13px; color: #666666"
            >）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">（</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >6</span
          ><span style="font-size: 13px; color: #666666"
            >）个人信息安全影响评估报告表明存在高风险时。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >当您使用我们的网站时，您可以随时审查我们的隐私政策，以便您了解其修改。您可以在本隐私通知的顶部的</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >“</span
          ><span style="font-size: 13px; color: #666666">最近更新</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >”</span
          ><span style="font-size: 13px; color: #666666"
            >的日期处查看本隐私政策的最新更新日期。</span
          >
        </p>
        <p style="background: white">
          <span
            style="font-size: 13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >&nbsp;</span
          >
        </p>
        <p style="background: white">
          <strong
            ><span
              style="font-size:15px;font-family:&#39;Tahoma&#39;,sans-serif;color:#3B99FC"
              >11</span
            ></strong
          ><strong
            ><span style="font-size: 15px; color: #3b99fc"
              >、如何联系我们</span
            ></strong
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >您可以通过以下方式与我们联系，一般情况下，我们将在</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >15</span
          ><span style="font-size: 13px; color: #666666"
            >个工作日内受理并处理您关于个人信息的请求。</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <a><span style="font-size: 13px; color: #666666">电话：</span></a
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >400-8844-365</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666">邮箱：</span
          ><span
            style="font-size:13px;font-family:&#39;Tahoma&#39;,sans-serif;color:#666666"
            >yanbingqian@1meeting.com</span
          >
        </p>
        <p style="text-indent: 32px; background: white">
          <span style="font-size: 13px; color: #666666"
            >如果您对我们的回复不满意，并且认为我们的个人信息处理行为损害了您的合法权益，您可以向客服进行投诉，也可以向公安机关或网信、电信、市场等监管部门进行投诉或举报，或向被告住所地有管辖权的人民法院提起诉讼。</span
          >
        </p>
        <p><br /></p>
        <p><br /></p>
      </div>
    </div>

    <div class="clause_box" v-if="showKey == 2">
      <div class="act_desc">
        <p style="text-align: center; background: white">
          <strong
            ><span style="font-size: 28px; color: #3b99fc"
              >服务条款</span
            ></strong
          >
        </p>
        <p style="text-align: right; line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >生效时间：2022年8月1日</span
          >
        </p>
        <p style="text-align: right; line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >更新时间：2022年8月1日</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >1</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、服务条款的确认</span
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >请您在使用<a>本网站</a></span
            ></strong
          ><strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >提供的任何服务前仔细阅读并确认您已充分理解服务条款，您主动勾选服务条款确认页面下的
              “已阅读并同意遵守”，即视为您已阅读、了解并完全同意服务条款中的各项内容。</span
            ></strong
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >除非本网站另行明确声明，本网站任何服务范围或功能的变化均受服务条款约束。请您仔细阅读以下内容，<strong>如果您不同意以下任何内容，请立刻停止访问/使用本网站或其他任何移动应用或软件所提供的相关服务。</strong>如果服务条款与其他协议或条款有不一致之处，则以其他协议或条款为准。</span
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >如您为不满18周岁的未成年人，您将无法自行注册本网站账户，您需要通过您的监护人注册账户或在取得您监护人同意的情形下由授权机构注册相关账户并为您提供本网站服务。</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 16px;
                line-height: 115%;
                font-family: 仿宋;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              ><strong
                ><span
                  style="
                    font-size: 16px;
                    font-family: 仿宋;
                    background: lime;
                    background: lime;
                  "
                  >如您为授权机构用户，您在向我们提供未成年人信息时应当已经取得父母或监护人的同意。</span
                ></strong
              ></span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >2</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、服务条款的修改</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本网站的服务条款可能不时更新，最新版的服务条款会在网站进行公告，一经公告，立即生效。<strong
              >如您继续使用服务，则视为您已接受修订的服务条款。如您不接受或不同意修改后的内容，请您立即停止使用本网站的各项服务。</strong
            ></span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >3</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、用户注册</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >考虑到本网站用户服务的重要性，网站报名用户的注册实行实名制，您同意在注册时<strong>提供真实、完整及准确的本人资料(包括姓名、性别、证件类型、证件号码、出生、日期等，以及与身份信息对应的且可通话联络的唯一手机号码)</strong>，如发生变化请及时更新。如果您提供的注册信息不真实、不准确、不完整、不合法，您需承担因此导致的后果及责任，我们保留终止您使用本网站提供的服务的权利。本网站有权对您提供的资料进行核验，核验结果适用于您在本网站办理注册以及报名等相关业务，</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本人<strong>同意通过短（彩）信、微信等方式接收推送的服务信息</strong>；如您提供的资料不准确，或无法通过网站核验、车站核验，或本网站有合理的理由认为该资料不真实、不完整、不准确，本网站有权暂停或终止您的注册身份及资料，并拒绝您使用本网站的服务。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本网站不支持第三方网站代购用户的注册，一经发现本网站有权停止对代购用户的报名服务。关于用户注册时的个人信息的收集、处理规则详见本网站的<span
              style="color: #3b99fc"
              @click="tochange()"
              >《隐私政策》</span
            ></span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >4</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、用户资料及保密</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >注册时，请您选择填写用户名和密码，并按页面提示提交相关信息。<strong>您有义务妥善保管您在本网站的账号及密码，并正确、安全地使用您的账号及密码。</strong>由于您未尽该义务导致账号密码遗失、账号被盗等情形而给自身造成损害的，由您自己承担由此产生的直接和间接损失。如因您自身过错或用户计算机感染病毒或木马，而导致账号或密码泄漏的，您应自行承担由此造成的损失。您对通过您的账号所发生的所有活动承担责任，无论是否由您本人操作。请您不要把用户名、密码和注册时填写的个人信息提供给第三方网站或他人使用，否则由您自己承担风险和后果。<strong
              >鉴于网络服务的特殊性，本网站无义务审核是否是您本人使用该组用户名及密码，仅审核用户名及密码是否与数据库中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并使用本网站所提供的各类服务，所以即使您认为您的账户登陆行为并非您本人所为，本网站将不承担因此而产生的任何责任。</strong
            ></span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >用户在本网站的账号所有权归本网站所有，用户完成账号注册后，仅根据服务条款及相关规则获得账号的使用权，且该使用权仅属于初始注册人。未经本网站的同意，用户不得对账号使用权进行买卖、赠与、交换、继承或其他任何形式的交易。若用户发现账号或密码被他人非法使用或存在异常使用的情形，应立即根据本网站公布的方式与本网站取得联系。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >5</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、用户隐私权保护</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >我们非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，<strong
              >在您使用网站提供的服务时，您同意网站按照在平台上公布的
              <span style="color: #3b99fc" @click="tochange()"
                >《隐私政策》</span
              >
              收集、存储、使用、披露和保护您的个人信息。</strong
            >为保护您的隐私，我们将按照网站<span
              style="color: #3b99fc"
              @click="tochange()"
              >《隐私政策》</span
            >的要求，对您的个人信息进行收集、存储、使用、披露和保护。网站希望通过<span
              style="color: #3b99fc"
              @click="tochange()"
              >《隐私政策》</span
            >向您清楚地介绍网站对您个人信息的处理方式，网站建议您完整地阅读<span
              style="color: #3b99fc"
              @click="tochange()"
              >《隐私政策》</span
            >，以帮助您更好地理解您的个人信息保护规则。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >6</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、用户行为规范</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >服务条款依据中华人民共和国相关法律法规规章制定，您同意严格遵守以下义务：</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（1）从中国大陆向境外传输资料信息时必须符合中国有关法规；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（2）
            不得利用本网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（3）不得以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等非本网站认可的方式访问或登录本网站，不得通过任何方式对本网站造成或可能造成不合理或不合比例的重大负荷；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（4）不得干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（5）不得过发送病毒等行为攻击本网站其它用户的账号或终端设备；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（6）不得冒充本网站工作人员欺骗其他用户；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（7）
            不得利用在本网站注册的账户进行牟利性经营活动或协助他人获取不正当利益；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（8）不得发布任何侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（9）
            不得利用任何非法手段获取其他用户个人信息，不得将其他用户信息用于任何营利或非营利目的，不得泄露其他用户或权利人的个人信息，否则我们有权采取措施制止您的上述行为，情节严重的，将提交公安机关处理；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（10）不得教唆他人从事服务条款“6、用户行为规范”中所禁止的行为。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >在本网站中发表任何内容时，您同意并保证不发表包含以下言论的内容：</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（1）反对宪法所确定的基本原则，煽动、抗拒、破坏宪法和法律、行政法规实施的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（2）危害国家安全，泄露国家秘密，煽动颠覆国家政权，推翻社会主义制度，煽动、分裂国家，破坏国家统一的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（3）损害国家机关信誉的、损害国家荣誉和利益的、损害社会公共利益和涉及国家安全的；
          </span>
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（4）歪曲、丑化、亵渎、否定英雄烈士及其事迹和精神的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（5）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（6）任何包含对种族、性别、宗教、地域内容等歧视的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（7）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（8）破坏国家宗教政策，宣扬封建迷信、邪教、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（9）骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的、公然侮辱他人或者捏造事实诽谤他人的，损害他人名誉、隐私、知识产权或其他合法权益的，或者进行其他恶意攻击的；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（10）含有法律、行政法规禁止或危害社会公德等破坏网络生态的。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（11）含有污言秽语、色情低俗、广告信息及法律法规与服务条款列明的其他禁止性信息的。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >7</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、用户的违约责任</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本网站有权判定用户的行为是否构成违约，如用户使用本网站服务时违反有关法律法规规定或服务条款约定的，视为用户违约。</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本网站可依据用户在本网站的系统记录判定用户是否构成违约。用户有义务对其认为异常的系统记录进行充分举证和合理解释，否则将被认定为违约。</span
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >如因用户违反有关法律、法规或服务条款而给本网站或任何其他第三人造成损失，用户同意承担由此造成的一切法律责任（包括但不限于民事责任、行政责任、刑事责任等）。</span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >8</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、责任的免除和限制</span
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >遇以下情况，本网站不承担任何责任，包括但不仅限于：</span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >（1）
              因不可抗力、系统故障、通讯故障、网络拥堵、供电系统故障、恶意攻击等造成本网站未能及时、准确、完整地提供服务。</span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >（2）如用户利用系统差错、故障或其他原因导致的漏洞，损害本网站及任何第三方的权益，本网站将终止该用户资格，并保留法律追究的权利。</span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <strong
            ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
              >（3）如果本网站发现有影响用户信息安全的行为，本网站有权对用户信息实施保护。必要时用户需经重新验证身份后方可继续使用。</span
            ></strong
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >9</span
          ><span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >、拒绝提供担保</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >本网站对以下情形不做任何担保，包括但不仅限于：</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（1）服务一定能满足您的要求；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（2）服务不会受中断；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（3）服务的安全性、及时性、完整性和准确性；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（4）服务所涉及相关方的服务；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（5）您从本网站收到口头或书面的意见或信息；</span
          >
        </p>
        <p style="line-height: 115%">
          <span style="font-size: 16px; line-height: 115%; font-family: 仿宋"
            >（6）您所提供的身份信息一定被注册。</span
          >
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/clause.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";

export default {
  name: "Clause",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "",
      showKey: 1, // 显示设置
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let showKey = this.$route.params.showKey;
    if (showKey != undefined && showKey != "") {
      this.showKey = showKey;
      this.$store.commit("set_clauseKey", showKey);
    } else {
      this.showKey = this.$store.state.clauseKey;
    }
    if (this.showKey == 1) {
      this.topTitle = "隐私政策";
    } else if (this.showKey == 2) {
      this.topTitle = "服务条款";
    }
  },
  methods: {
    // 打开隐私条款
    tochange: function () {
      this.showKey = 1;
      this.topTitle = "隐私政策";
      window.scrollTo(0, 0);
    },
  },
};
</script>
