<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="order_body">
      <!-- 订单状态 -->
      <div class="order_status_box">
        <div class="order_status_left">
          <img
            class="order_status_icon"
            src="../../assets/image/success_icon03.png"
            v-if="orderData.order_status == 1"
          />
          <img
            class="order_status_icon"
            src="../../assets/image/success_icon02.png"
            v-if="orderData.order_status == 2"
          />
          <div class="order_status_value_o" v-if="orderData.order_status == 1">
            待支付
          </div>
          <div class="order_status_value_g" v-if="orderData.order_status == 2">
            报名付款成功
          </div>
          <div class="order_status_value_o" v-if="orderData.order_status == 6">
            已退款
          </div>
          <div class="order_status_value_o" v-if="orderData.order_status == 7">
            退款中
          </div>
        </div>
        <div class="order_status_countdown" v-if="orderData.order_status == 1">
          名额锁定中：{{ countdownText }}
        </div>
      </div>
      <!-- 考试信息 -->
      <div class="order_card">
        <div class="order_top_item">
          <img
            class="order_top_img"
            :src="baseAPIUrl + enrollData.image_path"
            alt="封面"
          />
          <div class="order_top_title">{{ enrollData.title }}</div>
        </div>
        <div class="order_top_item">
          <div class="order_top_time_box">
            <img
              class="order_top_icon"
              src="../../assets/image/time_icon01.png"
            />
            <div class="order_top_time">
              {{ enrollData.exam_date + " " + enrollData.exam_start_time }}
            </div>
          </div>
          <div class="order_top_price">￥ {{ enrollData.exam_money }}</div>
        </div>
        <div class="order_top_item_left">
          <img
            class="order_top_icon"
            src="../../assets/image/address_icon01.png"
            style="height: auto"
          />
          <div class="order_top_address_box">
            <div class="order_top_address">
              <div class="order_top_address_g">
                {{ enrollData.area_name }}
              </div>
              <div class="order_top_address_b">
                {{ enrollData.place }}
              </div>
            </div>
            <div class="order_top_address_detail">
              {{ enrollData.address }}
            </div>
          </div>
        </div>
      </div>
      <!-- 考生信息 -->
      <div
        class="order_card content_card"
        v-for="(item, index) in userData"
        :key="index"
      >
        <div class="order_tab_box">
          <div class="order_tab">考生信息</div>
          <div
            v-if="orderData.order_status == 2"
            class="order_tab_btn"
            @click="toCardFn(item.enroll_id)"
          >
            查看准考证
          </div>
        </div>
        <div class="order_tab_content_user">
          <div class="order_tab_content_left">
            <div class="order_tab_content_item">
              <div class="order_tab_content_item_title">姓名：</div>
              <div class="order_tab_content_item_value user_value">
                {{ item.name }}
              </div>
            </div>
            <div class="order_tab_content_item">
              <div class="order_tab_content_item_title">考试等级：</div>
              <div class="order_tab_content_item_value user_value">
                {{ enrollData.exam_level }}
              </div>
            </div>
            <div class="order_tab_content_item">
              <div class="order_tab_content_item_title">证件号：</div>
              <div class="order_tab_content_item_value user_value">
                {{ item.identity_number }}
              </div>
            </div>
          </div>
          <img
            class="order_tab_content_user_img"
            :src="baseAPIUrl + item.person_photo"
          />
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="order_card content_card" style="margin-bottom: 0">
        <div class="order_tab_content_item" style="margin-top: 0.24rem">
          <div class="order_tab_content_item_title">订单编号：</div>
          <div class="order_tab_content_item_value">
            {{ orderData.order_number }}
          </div>
        </div>
        <div class="order_tab_content_item" v-if="orderData.order_status != 1">
          <div class="order_tab_content_item_title">支付时间：</div>
          <div class="order_tab_content_item_value">
            {{ orderData.pay_time }}
          </div>
        </div>
        <div class="order_tab_content_item">
          <div class="order_tab_content_item_title">下单时间：</div>
          <div class="order_tab_content_item_value">
            {{ orderData.order_time }}
          </div>
        </div>
        <div class="order_tab_content_item">
          <div class="order_tab_content_item_title">推荐机构：</div>
          <div class="order_tab_content_item_value">
            {{ orderData.oguser_name }}
          </div>
        </div>
      </div>
      <!-- 操作栏 -->
      <div class="order_bottom" v-if="orderData.order_status == 1">
        <div class="order_bottom_left">
          <div class="order_bottom_price_label">总金额：</div>
          <div class="order_bottom_price">￥{{ orderData.exam_money }}</div>
        </div>
        <div class="order_bottom_right">
          <div
            class="order_bottom_btn_g"
            style="margin-right: 0.06rem"
            @click="cancelBtnFn()"
          >
            取消报名
          </div>
          <!-- <div class="order_bottom_btn_g" style="margin-right: 0.06rem">
            修改报名
          </div> -->
          <div class="order_bottom_btn_o" @click="payBtnFn()">立即支付</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/order/orderDetail.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Indicator, Toast } from "mint-ui";

export default {
  name: "OrderDetail",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "报名详情", // 顶部组件参数设置
      orderId: "", // 订单Id
      orderData: {
        id: "",
        order_number: "",
        order_time: "",
        exam_money: "",
        order_status: "",
        remain_time: "",
        pay_time: "",
        oguser_name: "",
      }, // 订单详细数据
      userData: [], // 考生信息数据
      enrollData: {
        id: "",
        image_path: "",
        title: "",
        exam_date: "",
        exam_money: "",
        area_name: "",
        place: "",
        address: "",
        exam_level: "",
        exam_start_time: "",
        exam_end_time: "",
      }, // 报名详情数据
      countdownText: "0分0秒", // 支付倒计时显示文本
      examLevelList: this.$store.state.levelList, // 等级过滤列表
      orderInterval: null,
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let orderId = this.$route.params.orderId;
    if (orderId != undefined && orderId != "") {
      this.orderId = orderId;
      this.$store.commit("set_watchOrderId", orderId);
    } else {
      this.orderId = this.$store.state.watchOrderId;
    }
    this.getDetailDataFn();
  },
  methods: {
    // 设置订单剩余时间
    setOrderTimeFn: function () {
      let that = this;
      this.orderInterval = setInterval(function () {
        let time = that.orderData.remain_time;
        if (time >= 0) {
          let minute = 0;
          if (time >= 60) {
            minute = parseInt(time / 60);
            time = parseInt(time % 60);
          }
          that.countdownText = minute + "分" + time + "秒";
          that.orderData.remain_time = that.orderData.remain_time - 1;
        } else {
          that.singelOrderOverdueDealFn();
          clearInterval(that.orderInterval);
        }
      }, 1000);
    },
    // 订单超时处理
    singelOrderOverdueDealFn: function () {
      let that = this;
      let url = "/mobile/singelOrderOverdueDeal";
      let data = {
        order_id: this.orderId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            Toast({
              message: "超出支付时间",
              duration: 3000,
            });
            that.$router.push({
              name: "Home",
            });
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查询订单详情数据
    getDetailDataFn: function () {
      let that = this;
      let url = "/mobile/enrollOrderDetail";
      let data = {
        order_id: this.orderId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.orderData = response.order;
            that.userData = response.students;
            that.enrollData = response.exam;
            if (response.order.order_status == 1) {
              that.setOrderTimeFn();
            }
            that.examLevelList.forEach(function (item) {
              if (item.key == response.exam.exam_level) {
                that.enrollData.exam_level = item.value;
              }
            });
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          Indicator.close();
          console.log(error);
        });
    },
    // 支付按钮
    payBtnFn: function () {
      let that = this;
      let url = "/mobile/wechatpay/enrollOrderJs";
      let data = {
        order_id: this.orderId,
      };
      Indicator.open("请稍等...");
      this.axios
        .post(url, data)
        .then((response) => {
          Indicator.close();

          if (response.code == 0) {
            that.payFn(response);
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          Indicator.close();
          console.log(error);
        });
    },
    // 调起支付
    payFn: function (data) {
      let that = this;
      let payData = data;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(payData),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            that.onBridgeReady(payData)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(payData)
          );
        }
      } else {
        this.onBridgeReady(payData);
      }
    },
    // 微信支付
    onBridgeReady: function (payData) {
      let that = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: payData.appId, //公众号ID，由商户传入
          timeStamp: payData.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: payData.nonceStr, //随机串
          package: payData.package,
          signType: payData.signType, //微信签名方式：
          paySign: payData.paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.toSuccessFn();
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            that.cancelPay();
          }
        }
      );
    },
    // 查看准考证
    toCardFn: function (id) {
      this.$router.push({
        name: "Card",
        params: {
          cardId: id,
        },
      });
    },
    // 取消订单
    cancelBtnFn: function () {
      let that = this;
      let url = "/mobile/cancelOrderEnroll";
      let data = {
        order_id: this.orderId,
      };
      MessageBox({
        message: "确认取消报名?",
        confirmButtonText: "确认",
        showCancelButton: true,
      }).then((action) => {
        if (action == "confirm") {
          that.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: "已取消报名",
                  duration: 3000,
                });
                that.$router.replace({
                  name: "Home",
                });
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    // 跳转至订单完成提示页
    toSuccessFn: function () {
      this.$router.push({
        name: "OrderSuccess",
        params: {
          orderId: this.orderId,
        },
      });
    },
    // 用户取消支付
    cancelPay: function () {
      let url = "/mobile/wechatpay/orderCancelPay";
      let data = {
        order_id: this.orderId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code != 0) {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  beforeDestroy: function () {
    clearInterval(this.orderInterval);
  },
};
</script>
