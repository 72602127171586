import { render, staticRenderFns } from "./checkArea.vue?vue&type=template&id=496c5af4&scoped=true&"
import script from "./checkArea.vue?vue&type=script&lang=js&"
export * from "./checkArea.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/enroll/checkArea.css?vue&type=style&index=0&id=496c5af4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496c5af4",
  null
  
)

export default component.exports