<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { Toast } from "mint-ui";

export default {
  components: {},
  data() {
    return {};
  },
  created: function () {
    this.getLevelListFn();
  },
  methods: {
    getLevelListFn: function () {
      let that = this;
      let url = "/mobile/levelText";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            let list = [];
            response.level_list.forEach(function (item) {
              let obj = {
                key: item.level,
                value: item.title,
              };
              list.push(obj);
            });
            that.$store.commit("set_levelList", list);
          } else {
            // Toast({
            //   message: response.msg,
            //   duration: 3000,
            // });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {},
  watch: {},
};
</script>
