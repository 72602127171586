import { render, staticRenderFns } from "./orderSuccess.vue?vue&type=template&id=566defca&scoped=true&"
import script from "./orderSuccess.vue?vue&type=script&lang=js&"
export * from "./orderSuccess.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/order/orderSuccess.css?vue&type=style&index=0&id=566defca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566defca",
  null
  
)

export default component.exports