<template>
  <div :class="[checkeTeamShow ? 'page_body page_body_over' : 'page_body']">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="check_time_body">
      <div class="check_time_content_box">
        <!-- 考试信息 -->
        <div class="check_time_card">
          <div class="check_time_top_item">
            <img
              class="check_time_top_img"
              :src="baseAPIUrl + enrollData.image_path"
              alt="封面"
            />
            <div class="check_time_top_title">{{ enrollData.title }}</div>
          </div>
          <div class="check_time_top_item">
            <div class="check_time_top_time_box">
              <img
                class="check_time_top_icon"
                src="../../assets/image/time_icon01.png"
              />
              <div class="check_time_top_time">{{ enrollData.exam_date }}</div>
            </div>
            <div class="check_time_top_price">
              ￥ {{ enrollData.exam_money }}
            </div>
          </div>
          <div class="check_time_top_item_left">
            <img
              class="check_time_top_icon"
              src="../../assets/image/address_icon01.png"
              style="height: auto"
            />
            <div class="check_time_top_address_box">
              <div class="check_time_top_address">
                <div class="check_time_top_address_g">
                  {{ enrollData.area_name }}
                </div>
                <div class="check_time_top_address_b">
                  {{ enrollData.place }}
                </div>
              </div>
              <div class="check_time_top_address_detail">
                {{ enrollData.address }}
              </div>
            </div>
          </div>
        </div>
        <!-- 时间选择 -->
        <div class="check_time_content">
          <div class="check_time_content_title">考试时间</div>
          <div class="check_time_content_list" v-if="AMTimeList.length > 0">
            <div class="check_time_tab">上午</div>
            <div
              :class="checkItemClass(item.id, item.remain_quantity)"
              v-for="(item, index) in AMTimeList"
              :key="index"
              @click="checkTimeFn(item.id, item.remain_quantity)"
            >
              <div class="check_time_content_item_value">
                {{ enrollData.exam_date + " " + item.exam_time_start }}
              </div>
              <div
                class="check_time_content_item_tips"
                v-if="item.remain_quantity == 0"
              >
                名额已满
              </div>
            </div>
          </div>

          <div class="check_time_content_list" v-if="PMTimeList.length > 0">
            <div class="check_time_tab">下午</div>
            <div
              :class="checkItemClass(item.id, item.remain_quantity)"
              v-for="(item, index) in PMTimeList"
              :key="index"
              @click="checkTimeFn(item.id, item.remain_quantity)"
            >
              <div class="check_time_content_item_value">
                {{ enrollData.exam_date + " " + item.exam_time_start }}
              </div>
              <div
                class="check_time_content_item_tips"
                v-if="item.remain_quantity == 0"
              >
                名额已满
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 确认报名 -->
      <div class="check_time_bottom">
        <div
          :class="[
            checkedTimeId != '' && agreeKey
              ? 'check_time_bottom_btn agree_btn'
              : 'check_time_bottom_btn',
          ]"
          @click="openCheckeTeamFn()"
        >
          {{ checkedTimeId != "" ? "确认" : "请选择考试时间" }}
        </div>
        <div class="check_time_bottom_clause_box">
          <div
            :class="[
              agreeKey
                ? 'check_time_bottom_check_box agree_icon_box'
                : 'check_time_bottom_check_box',
            ]"
            @click="changeAgreeKey()"
          >
            <img
              :class="[
                agreeKey
                  ? 'check_time_bottom_check_icon agree_icon'
                  : 'check_time_bottom_check_icon',
              ]"
              src="../../assets/image/checked_icon01.png"
            />
          </div>

          <div class="check_time_bottom_clause_words">选中代表您已阅读</div>
          <div class="check_time_bottom_clause" @click="toNoticeFn()">
            《考试须知》
          </div>
        </div>
      </div>

      <div class="checke_team_block" v-show="checkeTeamShow">
        <div class="checke_team_box">
          <img
            class="checke_team_box_close"
            src="../../assets/image/close_icon01.png"
            @click="closeCheckeTeamFn()"
          />
          <div class="checke_team_box_title">选择推荐机构</div>
          <div class="checke_team_box_tips">*如果没有推荐机构,请选择“其他”</div>
          <div class="checke_team_box_list">
            <div
              class="checke_team_box_list_item"
              v-for="(item, index) in checkeTeamList"
              :key="index"
              @click="checkeTeamFn(item)"
            >
              <div :class="setClassFn(item.id)">
                <img
                  class="check_team_list_item_check_icon"
                  src="../../assets/image/checked_icon02.png"
                />
              </div>
              <div class="checke_team_box_list_name">{{ item.name }}</div>
            </div>
          </div>
          <div class="checke_team_box_list_btn" @click="submitCheckFn()">
            确定
          </div>
        </div>
      </div>
    </div>
    <!-- <HomeFooter :activeKey="activeKey"></HomeFooter> -->
  </div>
</template>
<style src="../../assets/css/enroll/checkTime.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "CheckTime",
  components: {
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "选择时间", // 顶部组件参数设置
      userId: "", // 考生Id
      enrollData: {
        id: "",
        image_path: "",
        title: "",
        exam_date: "",
        area_name: "",
        exam_money: "",
        address: "",
        detail: "",
        notice: "",
      }, // 报名详情数据
      tabShowKey: 1, // 考试介绍选项卡显示
      AMTimeList: [], // 上午时间列表数据
      PMTimeList: [], // 下午时间列表数据
      checkedTimeId: "", // 已勾选考试时间id
      baseAPIUrl: this.$store.state.baseAPIUrl,
      checkeTeamShow: false, // 选择机构显示
      checkeTeamList: [], // 机构列表
      checkeOguserId: null,
      checkeOguserName: "",
      agreeKey: false, // 同意勾选
    };
  },
  created: function () {
    this.enrollId = this.$store.state.checkedEnrollId;
    let v = this.$route.params.userId;
    if (v != undefined && v != "") {
      this.userId = v;
      this.$store.commit("set_checkedUserId", v);
    } else {
      this.userId = this.$store.state.checkedUserId;
    }
    this.getEnrollDetailDataFn();
    this.getTimeListDataFn();
    this.getTeamListFn();
  },
  methods: {
    // 查询机构列表
    getTeamListFn: function () {
      let that = this;
      let url = "/mobile/oguserList";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            let list = [];
            response.user_info.forEach(function (item) {
              let obj = {
                id: item.id,
                name: item.name,
              };
              list.push(obj);
            });
            // let end = {
            //   id: "0",
            //   name: "无",
            // };
            // list.push(end);
            that.checkeTeamList = list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 选择机构
    checkeTeamFn: function (item) {
      if (this.checkeOguserId == item.id) {
        this.checkeOguserId = null;
        this.checkeOguserName = "";
      } else {
        this.checkeOguserId = item.id;
        this.checkeOguserName = item.name;
      }
    },
    // 打开选择机构
    openCheckeTeamFn: function () {
      let that = this;
      if (this.checkedTimeId != "") {
        if (this.agreeKey == true) {
          this.checkeTeamShow = true;
        } else {
          MessageBox({
            message: "请您阅读《考试须知》,勾选同意进行报名",
            confirmButtonText: "同意",
            showCancelButton: true,
          }).then((action) => {
            if (action == "confirm") {
              that.agreeKey = true;
            }
          });
        }
      }
    },
    // 勾选/取消已阅读
    changeAgreeKey: function () {
      this.agreeKey = !this.agreeKey;
    },
    toNoticeFn: function () {
      this.$router.push({
        name: "NoticeDetail",
      });
    },
    // 关闭选择机构
    closeCheckeTeamFn: function () {
      this.checkeTeamShow = false;
    },
    // 设置Class
    setClassFn(id) {
      if (this.checkeOguserId == id) {
        return "check_team_list_item_check is_checked";
      } else {
        return "check_team_list_item_check";
      }
    },
    // 查询时间列表数据
    getTimeListDataFn: function () {
      let that = this;
      let url = "/mobile/examtimeList";
      let data = {
        exam_id: this.enrollId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.AMTimeList = [];
            that.PMTimeList = [];
            response.exam_times.forEach(function (item) {
              let st = parseInt(item.exam_time_start.slice(0, 2));
              if (st < 13) {
                that.AMTimeList.push(item);
              } else {
                that.PMTimeList.push(item);
              }
            });
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查询报名详情数据
    getEnrollDetailDataFn: function () {
      let that = this;
      let url = "/mobile/examDetail";
      let data = {
        exam_id: this.enrollId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.enrollData = response.exam_info;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 动态返回时间item的class
    checkItemClass: function (id, remain_quantity) {
      if (remain_quantity == 0) {
        return "check_time_content_item disabled_item";
      } else {
        if (id == this.checkedTimeId) {
          return "check_time_content_item checked_item";
        } else {
          return "check_time_content_item";
        }
      }
    },
    // 选择时间
    checkTimeFn: function (id, remain_quantity) {
      if (remain_quantity != 0) {
        if (this.checkedTimeId == id) {
          this.checkedTimeId = "";
        } else {
          this.checkedTimeId = id;
        }
      }
    },
    // 提交生成订单
    submitCheckFn: function () {
      let that = this;
      if (this.checkedTimeId != "") {
        if (this.checkeOguserId != "" && this.checkeOguserId != null) {
          MessageBox.confirm("确认提交报名?")
            .then((action) => {
              console.log(action);
              let url = "/mobile/createEnrollOrder";
              let data = {
                exam_id: this.enrollId,
                stu_ids: this.userId,
                examtime_id: this.checkedTimeId,
                oguser_id: this.checkeOguserId,
                oguser_name: this.checkeOguserName,
              };
              this.axios
                .post(url, data)
                .then((response) => {
                  if (response.code == 0) {
                    that.toCreateOrder(response.order_id);
                  } else {
                    Toast({
                      message: response.msg,
                      duration: 3000,
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Toast({
            message: "请选择推荐机构",
            duration: 3000,
          });
        }
      }
    },
    // 前往生成订单
    toCreateOrder: function (order_id) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          orderId: order_id,
        },
      });
    },
  },
};
</script>
