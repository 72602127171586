<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="add_user_body">
      <div class="add_user_from_box">
        <div class="top_tips">请填写真实有效的考生信息</div>
        <div class="add_user_from_item upload_img_item">
          <div class="add_user_from_item_label upload_img_Label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/img_path_icon01.png"
            />
          </div>
          <div class="upload_img_box">
            <img
              v-if="addData.person_photo == null || addData.person_photo == ''"
              class="upload_img_box_icon"
              src="../../assets/image/upload_img_bg01.png"
            />
            <img
              v-if="addData.person_photo != null && addData.person_photo != ''"
              class="upload_img_box_icon"
              :src="baseAPIUrl + addData.person_photo"
            />
            <input
              type="file"
              accept="image/*"
              ref="uploadImg"
              id="upload_img_btn"
              @change="uploadImgBtn($event)"
            />
          </div>
          <div v-if="uploadTipsShow" class="upload_tips_show">
            图片上传中...
          </div>
        </div>

        <div class="add_user_from_item">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/user_icon01.png"
            />
          </div>
          <input
            class="add_user_from_item_input"
            placeholder="请输入姓名"
            type="text"
            v-model="addData.name"
            maxlength="6"
          />
        </div>

        <div class="add_user_from_item" style="justify-content: flex-start">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/identity_type_icon01.png"
            />
          </div>
          <div class="from_item_select">
            <div class="from_item_select_value" @click="openSelectFn()">
              {{ selectShow }}
            </div>
            <img
              class="from_item_select_icon"
              src="../../assets/image/select_icon01.png"
              @click="openSelectFn()"
            />
            <div
              v-show="selectShowKey"
              class="from_item_select_block"
              @click="closeSelectOptionFn()"
            ></div>
            <div v-show="selectShowKey" class="from_item_select_option_list">
              <div
                class="from_item_select_option"
                v-for="(item, index) in certificatesTypeList"
                :key="index"
                @click="selectOptionFn(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="add_user_from_item">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/identity_number_icon01.png"
            />
          </div>
          <input
            class="add_user_from_item_input"
            placeholder="请输入证件号"
            type="text"
            v-model="addData.identity_number"
            maxlength="20"
          />
        </div>

        <div class="add_user_from_item" style="justify-content: flex-start">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/gender_icon01.png"
            />
          </div>
          <div class="from_item_select">
            <div class="from_item_select_value" @click="openGenderSelectFn()">
              {{ genderSelectShow }}
            </div>
            <img
              class="from_item_select_icon"
              src="../../assets/image/select_icon01.png"
              @click="openGenderSelectFn()"
            />
            <div
              v-show="genderSelectShowKey"
              class="from_item_select_block"
              @click="closeGenderSelectOptionFn()"
            ></div>
            <div
              v-show="genderSelectShowKey"
              class="from_item_select_option_list"
            >
              <div
                class="from_item_select_option"
                v-for="(item, index) in genderList"
                :key="index"
                @click="selectGenderOptionFn(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="add_user_from_item">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/age_icon01.png"
            />
          </div>
          <div
            class="add_user_from_age_show"
            @click="openDatetimePicker()"
            v-if="addData.age != ''"
          >
            {{ addData.age }}
          </div>
          <div
            class="add_user_from_age_show_none"
            @click="openDatetimePicker()"
            v-else
          >
            请选择出生日期
          </div>
        </div>

        <div class="add_user_from_item">
          <div class="add_user_from_item_label">
            <img
              class="add_user_from_item_label_icon"
              src="../../assets/image/height_icon01.png"
              style="width: 0.36rem"
            />
          </div>
          <div class="height_input_item_box">
            <input
              class="add_user_from_item_input height_input_item"
              placeholder="请输入身高"
              type="number"
              v-model="addData.height"
              maxlength="2"
            />
            <div class="add_user_from_item_input_unit">cm</div>
          </div>
        </div>
      </div>
      <div class="add_user_from_send_btn" @click="addUseFn()">添加</div>
    </div>
    <mt-datetime-picker
      ref="picker"
      type="date"
      :startDate="startDate"
      v-model="datetimePicker"
      @confirm="confirmSetAge()"
    >
    </mt-datetime-picker>
  </div>
</template>
<style src="../../assets/css/user/addUser.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";
// 引入微信JSSDK
// import wx from "weixin-js-sdk";

export default {
  name: "AddUser",
  components: {
    HomeTop,
  },
  data() {
    return {
      baseAPIUrl: this.$store.state.baseAPIUrl,
      startDate: new Date("1990/01/01"),
      topTitle: "考生信息填写",
      memid: "", // 平台中用户id
      addData: {
        person_photo: "",
        name: "",
        identity_number: "",
        age: "",
        height: "",
        identity_type: 1,
        gender: "男",
      }, // 提交数据
      wxJSSDKConfig: {}, // 微信JSSDK配置
      uploadUrl: "", // 提交用图片路径
      certificatesTypeList: [
        {
          value: 1,
          label: "身份证",
        },
        {
          value: 2,
          label: "其他",
        },
      ], // 证件类型数组
      selectShow: "身份证", // 模拟select显示
      selectShowKey: false, // 下拉显示
      datetimePicker: "", //时期选择值
      realUrl: "",
      clickKey: true,
      genderList: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
      genderSelectShow: "男", // 模拟性别select显示
      genderSelectShowKey: false, // 性别下拉显示
      uploadTipsShow: false, // 上传中提示
    };
  },
  created: function () {
    let source = this.$route.params.source;
    if (source != undefined && source != "") {
      this.$store.commit("set_userDetaiSource", source);
    } else {
      source = this.$store.state.checkedEnrollId;
    }
    this.memid = this.$store.state.loginUserId;
    this.realUrl = this.$store.state.baseUrl + this.$route.fullPath;
  },
  methods: {
    // 添加
    addUseFn: function () {
      let that = this;
      let url = "/mobile/addStudent";
      let data = this.addData;
      if (data.name == "") {
        Toast({
          message: "请填写姓名",
          duration: 3000,
        });
        return false;
      }

      if (data.identity_number == "") {
        Toast({
          message: "请填写证件号",
          duration: 3000,
        });
        return false;
      } else if (data.identity_type == 1) {
        // 验证身份正则
        let idNumberText =
          /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
        if (!idNumberText.test(data.identity_number)) {
          Toast({
            message: "身份证格式不正确",
          });
          return false;
        }
      }

      if (data.age == "") {
        Toast({
          message: "请填写年龄",
          duration: 3000,
        });
        return false;
      }

      if (data.height == "") {
        Toast({
          message: "请填写身高",
          duration: 3000,
        });
        return false;
      }

      data.person_photo = this.uploadUrl;
      if (data.person_photo == "") {
        Toast({
          message: "请先上传照片",
          duration: 3000,
        });
        return false;
      }

      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$router.go(-1);
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取WXJSSDK配置
    getWXJSSDKConfigFn: function () {
      let that = this;
      let data = {};
      data = {
        url: this.realUrl,
      };
      let url = "/mobile/getjssdkconfig";
      return new Promise(function (resolve, reject) {
        that.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              that.wxJSSDKConfig = response.signpackage;
              resolve(true);
            } else {
              that.clickKey = true;
              Toast({
                message: response.msg,
                duration: 3000,
              });
              resolve(false);
            }
          })
          .catch(function (error) {
            that.clickKey = true;
            reject(error);
          });
      });
    },
    // 上传图片按钮
    // uploadImgBtn: function () {
    //   let that = this;
    //   if (this.clickKey) {
    //     that.clickKey = false;
    //     this.getWXJSSDKConfigFn().then((r) => {
    //       if (r) {
    //         wx.config({
    //           debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //           appId: that.wxJSSDKConfig.appid, // 必填，公众号的唯一标识
    //           timestamp: that.wxJSSDKConfig.time, // 必填，生成签名的时间戳
    //           nonceStr: that.wxJSSDKConfig.password, // 必填，生成签名的随机串
    //           signature: that.wxJSSDKConfig.signature, // 必填，签名
    //           jsApiList: ["chooseImage", "getLocalImgData"], // 必填，需要使用的 JS 接口列表
    //         });
    //         wx.ready(function () {
    //           that.clickKey = true;
    //           wx.chooseImage({
    //             count: 1, // 默认9
    //             sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
    //             sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
    //             success: function (res) {
    //               let localIds = res.localIds[0]; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
    //               wx.getLocalImgData({
    //                 localId: localIds, // 图片的localID
    //                 success: function (res) {
    //                   let localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示
    //                   // that.addData.person_photo = localData;
    //                   if (localData.indexOf("data:image") != 0) {
    //                     localData = "data:image/png;base64," + localData;
    //                   }
    //                   that.uploadImgFn(localData);
    //                 },
    //               });
    //             },
    //           });
    //         });

    //         wx.error(function (res) {
    //           if (
    //             res.errMsg == "config:invalid signature" &&
    //             res.realAuthUrl[0] != "" &&
    //             res.realAuthUrl[0] != undefined
    //           ) {
    //             that.clickKey = true;
    //             that.realUrl = res.realAuthUrl[0];
    //           }
    //           // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
    //         });
    //       }
    //     });
    //   }
    // },
    // 上传图片到服务器
    uploadImgFn: function (imgData) {
      let that = this;
      let url = "/upload/uploadImage";
      let data = {
        source: 1,
        img: imgData,
      };
      this.uploadTipsShow = true;
      this.axios
        .post(url, JSON.stringify(data))
        .then((response) => {
          this.uploadTipsShow = false;
          if (response.code == 0) {
            // that.wxJSSDKConfig = response.signpackage;
            let path = response.image_path;
            that.uploadUrl = response.image_path;
            that.addData.person_photo = path;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          this.uploadTipsShow = false;
          console.log(error);
        });
    },
    // 设置年龄
    idChangeFn: function () {
      // 验证身份正则
      if (this.addData.identity_type == 1) {
        let date = new Date();
        let str = this.addData.identity_number + "";
        let idNumberText =
          /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
        if (!idNumberText.test(str)) {
          Toast({
            message: "身份证格式不正确",
          });
          return false;
        } else {
          let newStr = new Date(
            str.slice(6, 10) + "-" + str.slice(10, 12) + "-" + str.slice(12, 14)
          );
          let yaer = newStr.getFullYear();
          let month = newStr.getMonth();
          let day = newStr.getDate();
          let age = date.getFullYear() - yaer;
          if (
            date.getMonth() < month ||
            (date.getMonth() === month && day > date.getDate())
          ) {
            age = age - 1;
          }
          this.addData.age = age;
        }
      }
    },
    // 上传图片
    uploadImgBtn: function (e) {
      let that = this;
      let file = e.target.files[0];
      if (file.size > 1024 * 1024 * 3) {
        Toast({
          message: "照片大小超过3MB",
          duration: 3000,
        });
      } else {
        if (file != undefined && file != null) {
          let reader = new FileReader();
          reader.onload = (res) => {
            let image = new Image();
            image.onload = function () {
              let width = image.width;
              let height = image.height;
              let cl = height / width;
              if (cl >= 1.28 && cl <= 1.52) {
                that.uploadImgFn(res.target.result);
              } else {
                that.$refs.uploadImg.value = "";
                Toast({
                  message: "照片尺寸不合规",
                  duration: 3000,
                });
              }
            };
            image.src = res.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
    },
    // 打开下拉
    openSelectFn: function () {
      this.selectShowKey = true;
    },
    // 隐藏下拉
    closeSelectOptionFn: function () {
      this.selectShowKey = false;
    },
    // 选择选项
    selectOptionFn: function (item) {
      this.addData.identity_type = item.value;
      this.selectShow = item.label;
      this.selectShowKey = false;
    },
    // 打开时间选择
    openDatetimePicker: function () {
      if (this.addData.age == "") {
        this.datetimePicker = new Date();
      } else {
        this.datetimePicker = this.addData.age;
      }
      this.$refs.picker.open();
    },
    // 设置时间回调
    confirmSetAge: function () {
      let newStr = new Date(this.datetimePicker);
      let yaer = newStr.getFullYear();
      let month = newStr.getMonth() + 1;
      let day = newStr.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.addData.age = yaer + "-" + month + "-" + day;
    },
    // 打开性别下拉
    openGenderSelectFn: function () {
      this.genderSelectShowKey = true;
    },
    // 隐藏性别下拉
    closeGenderSelectOptionFn: function () {
      this.genderSelectShowKey = false;
    },
    // 选择性别选项
    selectGenderOptionFn: function (item) {
      this.addData.gender = item.value;
      this.genderSelectShow = item.label;
      this.genderSelectShowKey = false;
    },
  },
};
</script>
