<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="video_body">
      <div class="video_box">
        <video
          class="video_ref"
          :src="videoData.url"
          controls
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
          x-webkit-airplay="allow"
          auto-pause-if-navigate
          objectFit="cover"
        ></video>
      </div>
      <div class="video_content_box">
        <div class="video_title">{{ videoData.name }}</div>
        <div class="video_content">{{ videoData.detail }}</div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/course/videoDetail.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import CourseListData from "../../../public/data/course.json";
import { Toast } from "mint-ui";

export default {
  name: "VideoDetail",
  components: {
    HomeTop,
  },
  data() {
    return {
      activeKey: 1,
      topTitle: "",
      videoId: "", // 视频id
      levelId: "", // 一级Id
      secondId: "", // 二级Id
      videoData: {
        id: 0,
        level: 0,
        first_level: 0,
        second_level: 0,
        name: "",
        url: "",
        detail: "",
      },
    };
  },
  created: function () {
    let v = this.$route.params.videoId;
    this.levelId = this.$store.state.checkedLevelId;
    this.secondId = this.$store.state.checkedSecondId;
    if (v != undefined && v != "") {
      this.videoId = v;
      this.$store.commit("set_watchVideoId", v);
    } else {
      this.videoId = this.$store.state.watchVideoId;
    }
    // this.setNameFn();
    this.getVideoDataFn();
  },
  methods: {
    // 设置列表数据
    setNameFn: function () {
      let that = this;
      let data = CourseListData.data;
      let level = data.findIndex((item) => item.key == that.levelId);
      let second = data[level].children.findIndex(
        (item) => item.key == that.secondId
      );
      let videoIndex = data[level].children[second].video.findIndex(
        (item) => item.id == that.videoId
      );
      this.topTitle = data[level].children[second].video[videoIndex].title;
    },
    // 获取视频数据
    getVideoDataFn: function () {
      let that = this;
      let url = "/mobile/examvideolist";
      let data = {
        id: this.videoId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.topTitle = response.msg[0].name;
            that.videoData = response.msg[0];
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
