<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="admin_signin_box">
      <div class="admin_signin_body" ref="cardDataBody">
        <div class="admin_signin_body_top">
          <img
            class="admin_signin_body_top_img"
            src="../../assets/image/login_img01.png"
          />
          <div class="admin_signin_body_top_right">
            <img
              class="admin_signin_body_top_title_img"
              src="../../assets/image/card_title01.png"
            />
          </div>
        </div>
        <div class="admin_signin_body_bottom">
          <div class="admin_signin_body_bottom_title">
            准考证号：&nbsp;&nbsp;{{ cardData.exam_stu_number }}
          </div>
          <div class="admin_signin_body_bottom_content">
            <img
              class="admin_signin_body_bottom_userImg"
              :src="baseAPIUrl + cardData.person_photo"
            />
            <div class="admin_signin_body_bottom_list">
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">姓名</div>
                <div class="admin_signin_body_bottom_value">
                  {{ cardData.stu_name }}
                </div>
              </div>
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">性别</div>
                <div class="admin_signin_body_bottom_value">
                  {{ cardData.gender }}
                </div>
              </div>
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">考场</div>
                <div class="admin_signin_body_bottom_value">
                  {{ cardData.address }}
                </div>
              </div>
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">等级</div>
                <div class="admin_signin_body_bottom_value">
                  {{ examLevelFilterFn(cardData.exam_level) }}
                </div>
              </div>
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">日期</div>
                <div class="admin_signin_body_bottom_value">
                  {{ cardData.exam_date }}
                </div>
              </div>
              <div class="admin_signin_body_bottom_item">
                <div class="admin_signin_body_bottom_label">时间</div>
                <div class="admin_signin_body_bottom_value">
                  {{ cardData.exam_start_time }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="admin_signin_bottom_box" v-if="cardData.signin == 1">
        <div class="admin_signin_bottom_btn_r" @click="stuSigninFn(2)">
          签到
        </div>
      </div>
      <div class="admin_signin_bottom_box" v-if="cardData.signin == 2">
        <div class="admin_signin_bottom_btn_r">
          <img
            class="admin_signin_bottom_btn_icon"
            src="../../assets/image/checked_icon02.png"
          />
          <div>已签到</div>
        </div>
        <div class="admin_signin_bottom_btn_g" @click="stuSigninFn(1)">
          取消签到
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/admin/adminSignin.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "AdminSignin",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "签到",
      wxJSSDKConfig: {}, // 微信JSSDK配置
      signinId: "", // 签到二维码所含报名id
      cardData: {
        exam_stu_number: "",
        stu_name: "",
        place: "",
        address: "",
        exam_level: "",
        exam_date: "",
        exam_start_time: "",
        exam_end_time: "",
        person_photo: "",
        gender: "",
      },
      canvasImgPath: "", // 生成图片的路径
      examLevelList: this.$store.state.levelList, // 等级过滤列表
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let signinId = this.$route.query.id;
    if (signinId != undefined && signinId != "") {
      this.signinId = signinId;
      this.$store.commit("set_signinId", signinId);
    } else {
      this.signinId = this.$store.state.signinId;
    }
    this.getDataFn();
  },
  methods: {
    // 返回技能等级
    examLevelFilterFn: function (level) {
      let index = this.examLevelList.findIndex((item) => item.key == level);
      if (index > -1) {
        return this.examLevelList[index].value;
      }
    },
    // 查询准考证数据
    getDataFn: function () {
      let that = this;
      let url = "/mobile/examiner/stuTicketInfo";
      let data = {
        enroll_id: this.signinId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.cardData = response.ticketInfo;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 签到/取消签到
    stuSigninFn: function (key) {
      let that = this;
      let url = "/mobile/examiner/stuSignin";
      let data = {
        enroll_id: this.signinId,
        signin: key,
      };
      let text = "";
      if (key == 1) {
        text = "取消签到";
      } else {
        text = "确认签到";
      }
      MessageBox({
        message: "是否" + text,
        confirmButtonText: "确认",
        showCancelButton: true,
      }).then((action) => {
        if (action == "confirm") {
          this.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: text + "成功",
                  duration: 3000,
                });
                that.getDataFn();
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
