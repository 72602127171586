<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="course_list_body">
      <div class="course_list">
        <div
          class="course_list_item"
          v-for="(item, index) in listShowData"
          :key="index"
          @click="toSecondLevelFn(item.id)"
        >
          <!-- <img
            class="course_list_item_img"
            src="../../assets/image/login_img01.png"
          /> -->
          <div class="course_list_item_title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/course/courseList.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import CourseListData from "../../../public/data/course.json";

export default {
  name: "CourseList",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 1,
      topTitle: "考级示范",
      listShowData: [], // 列表数据
    };
  },
  created: function () {
    let that = this;
    let data = CourseListData.data;
    data.forEach(function (item) {
      let obj = {
        id: item.key,
        title: item.title,
      };
      that.listShowData.push(obj);
    });
  },
  methods: {
    // 选择一级
    toSecondLevelFn: function (levelId) {
      this.$router.push({
        name: "SecondLevelList",
        params: { levelId: levelId },
      });
    },
  },
};
</script>
