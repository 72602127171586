<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="card_body">
      <div class="card_data_body" v-show="!canvasImgShow" ref="cardDataBody">
        <div class="card_data_body_top">
          <img
            class="card_data_body_top_img"
            src="../../assets/image/login_img01.png"
          />
          <div class="card_data_body_top_right">
            <img
              class="card_data_body_top_title_img"
              src="../../assets/image/card_title01.png"
            />
          </div>
        </div>
        <div class="card_data_body_bottom">
          <div class="card_data_body_bottom_title">
            准考证号：&nbsp;&nbsp;{{ cardData.exam_stu_number }}
          </div>
          <div class="card_data_body_bottom_content">
            <img
              class="card_data_body_bottom_userImg"
              :src="baseAPIUrl + cardData.person_photo"
            />
            <div class="card_data_body_bottom_list">
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">姓名</div>
                <div class="card_data_body_bottom_value">
                  {{ cardData.stu_name }}
                </div>
              </div>
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">性别</div>
                <div class="card_data_body_bottom_value">
                  {{ cardData.gender }}
                </div>
              </div>
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">考场</div>
                <div class="card_data_body_bottom_value">
                  {{ cardData.address }}
                </div>
              </div>
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">等级</div>
                <div class="card_data_body_bottom_value">
                  {{ examLevelFilterFn(cardData.exam_level) }}
                </div>
              </div>
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">日期</div>
                <div class="card_data_body_bottom_value">
                  {{ cardData.exam_date }}
                </div>
              </div>
              <div class="card_data_body_bottom_item">
                <div class="card_data_body_bottom_label">时间</div>
                <div class="card_data_body_bottom_value">
                  {{ cardData.exam_start_time }}
                </div>
              </div>
            </div>
          </div>
          <div class="card_data_body_qrcode_box">
            <div ref="qrCode_box" class="card_data_body_qrcode"></div>
            <div class="card_data_body_qrcode_tips">准考证二维码</div>
          </div>
          <div class="card_bottom_tips">
            提示：考生应于考试开始前30分钟到达考场进行检录候场，迟到10分钟者不予参加考试。
          </div>
        </div>
      </div>
      <div class="card_img_box" v-show="canvasImgShow">
        <img class="card_show_img" :src="canvasImgPath" />
      </div>
      <div class="card_bottom_btn" @click="toBackFn()">返回</div>
      <div class="card_bottom_btn" @click="toBackHomeFn()">返回首页</div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/card.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
import { Toast } from "mint-ui";

export default {
  name: "Card",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "准考证", // 顶部组件参数设置
      cardId: "", // 考试id
      cardData: {
        exam_stu_number: "",
        stu_name: "",
        place: "",
        address: "",
        exam_level: "",
        exam_date: "",
        exam_start_time: "",
        exam_end_time: "",
        person_photo: "",
        gender: "",
      },
      canvasImgPath: "", // 生成图片的路径
      canvasImgShow: false,
      examLevelList: this.$store.state.levelList, // 等级过滤列表
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let cardId = this.$route.params.cardId;
    if (cardId != undefined && cardId != "") {
      this.cardId = cardId;
      this.$store.commit("set_watchCardId", cardId);
    } else {
      this.cardId = this.$store.state.watchCardId;
    }
    this.getDataFn();
  },
  methods: {
    // 返回技能等级
    examLevelFilterFn: function (level) {
      let index = this.examLevelList.findIndex((item) => item.key == level);
      if (index > -1) {
        return this.examLevelList[index].value;
      }
    },
    // 查询准考证数据
    getDataFn: function () {
      let that = this;
      let url = "/mobile/examTicketInfo";
      let data = {
        enroll_id: this.cardId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.cardData = response.ticketInfo;
            that
              .createQRCodeFn(that.cardId, that.cardData.exam_stu_number)
              .then((res) => {
                if (res) {
                  that.$nextTick(function () {
                    that.createImgFn();
                  });
                }
              });
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 生成二维码
    createQRCodeFn: function (id, exam_stu_number) {
      let that = this;
      return new Promise(function (resolve) {
        setTimeout(function () {
          let text = {
            id: id,
            exam_stu_number: exam_stu_number,
          };
          let qrcodejs2 = new QRCode(that.$refs.qrCode_box, {
            text: JSON.stringify(text), //二维码内容
            colorDark: "#000000",
            colorLight: "#ffffff",
          });
          console.log(qrcodejs2);
          resolve(true);
        }, 1000);
      });
    },
    // 返回
    toBackFn: function () {
      this.$router.go(-1);
    },
    // 返回首页
    toBackHomeFn: function () {
      this.$router.push({
        name: "Home",
      });
    },
    // 生成图片
    createImgFn: function () {
      let that = this;
      let cardDataBody = this.$refs.cardDataBody;
      let option = {
        backgroundColor: "#F0F0F0",
        allowTaint: true,
      };
      html2canvas(cardDataBody, option).then((canvas) => {
        that.canvasImgPath = canvas.toDataURL();
        that.canvasImgShow = true;
      });
    },
  },
};
</script>
