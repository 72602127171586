import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseUrl: "https://shslqxh.1meeting.com", // 默认前端地址
    baseAPIUrl: "https://shslqxh.1meeting.com/api", // 默认后端地址
    // 基本信息
    loginToken: JSON.parse(sessionStorage.getItem("LOGIN_TOKEN")) || "", // 登录验证token
    adminLoginToken:
      JSON.parse(sessionStorage.getItem("ADMIN_LOGIN_TOKEN")) || "", // 考场管理登录验证token
    loginUserId: JSON.parse(sessionStorage.getItem("LOGIN_USERID")) || "", // 登录用户id
    isRegister: JSON.parse(sessionStorage.getItem("IS_REGISTER")) || false, // 登录用户是否完善信息
    levelList: JSON.parse(sessionStorage.getItem("LEVELLIST")) || [], // 考试等级列表

    // 用户提交报名已选信息
    checkedGradeId: JSON.parse(sessionStorage.getItem("CHECKED_GRADEID")) || "", // 当前已选考试等级id
    checkedAreaId: JSON.parse(sessionStorage.getItem("CHECKED_AREAID")) || "", // 当前已选地区id
    checkedEnrollId:
      JSON.parse(sessionStorage.getItem("CHECKED_ENROLLID")) || "", // 当前已选考试活动id
    agreeEnrollKey:
      JSON.parse(sessionStorage.getItem("AGREE_ENROLLKEY")) || false, // 是否同意考试条款
    checkedUserId: JSON.parse(sessionStorage.getItem("CHECKED_USERID")) || "", // 当前已选考生id

    // 页面缓存信息
    watchOrderId: JSON.parse(sessionStorage.getItem("WATCH_ORDERID")) || "", // 当前查看订单id
    watchCardId: JSON.parse(sessionStorage.getItem("WATCH_CARDID")) || "", // 当前查看准考证id
    userDetaiSource:
      JSON.parse(sessionStorage.getItem("USER_DETAI_SOURCE")) || "", // 学生列表来源 1.考生列表;2.选择考生
    editUserId: JSON.parse(sessionStorage.getItem("EDIT_USERID")) || "", // 当前修改考生id
    checkedLevelId: JSON.parse(sessionStorage.getItem("CHECKED_LEVELID")) || "", // 当前选择一级id
    checkedSecondId:
      JSON.parse(sessionStorage.getItem("CHECKED_SECONDID")) || "", // 当前选择二级id
    watchVideoId: JSON.parse(sessionStorage.getItem("WATCH_VIDEOID")) || "", // 当前查看视频id
    agreeBtnShow: JSON.parse(sessionStorage.getItem("AGREE_BTN_SHOW")) || false, // 详情页是否显示按钮
    editKey: JSON.parse(sessionStorage.getItem("EDIT_KEY")) || false, // 是否为修改个人信息
    clauseKey: JSON.parse(sessionStorage.getItem("CLAUSE_KEY")) || "", // 查看条款类型
    signinId: JSON.parse(sessionStorage.getItem("SIGNIN_ID")) || "", // 确认签到准考证号
    AdminExaminationId:
      JSON.parse(sessionStorage.getItem("ADMIN_EXAMINATION_ID")) || "", // 签到管理考场id
    watchCertificateId:
      JSON.parse(sessionStorage.getItem("WATCH_CERTIFICATEID")) || "", // 当前查看证书id
  },
  mutations: {
    // 更新token
    set_loginToken(state, value) {
      state.loginToken = value;
      if (value != "") {
        sessionStorage.setItem("LOGIN_TOKEN", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("LOGIN_TOKEN");
      }
    },
    // 更新考场管理登录验证token
    set_adminLoginToken(state, value) {
      state.adminLoginToken = value;
      if (value != "") {
        sessionStorage.setItem("ADMIN_LOGIN_TOKEN", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("ADMIN_LOGIN_TOKEN");
      }
    },
    // 更新登录id
    set_loginUserId(state, value) {
      state.loginUserId = value;
      if (value != "") {
        sessionStorage.setItem("LOGIN_USERID", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("LOGIN_USERID");
      }
    },
    // 更新登录用户是否完善信息
    set_isRegister(state, value) {
      if (value == 1) {
        state.isRegister = false;
        sessionStorage.setItem("IS_REGISTER", JSON.stringify(false));
      } else {
        state.isRegister = true;
        sessionStorage.setItem("IS_REGISTER", JSON.stringify(true));
      }
    },
    // 更新考试等级列表
    set_levelList(state, value) {
      state.levelList = value;
      sessionStorage.setItem("LEVELLIST", JSON.stringify(value));
    },

    // 选择考试等级
    set_checkedGradeId(state, value) {
      state.checkedGradeId = value;
      sessionStorage.setItem("CHECKED_GRADEID", JSON.stringify(value));
    },
    // 选择地区
    set_checkedAreaId(state, value) {
      state.checkedAreaId = value;
      sessionStorage.setItem("CHECKED_AREAID", JSON.stringify(value));
    },
    // 选择考试活动
    set_checkedEnrollId(state, value) {
      state.checkedEnrollId = value;
      sessionStorage.setItem("CHECKED_ENROLLID", JSON.stringify(value));
    },
    // 同意条款
    set_agreeEnrollKey(state, value) {
      if (value == 1) {
        state.agreeEnrollKey = false;
        sessionStorage.setItem("AGREE_ENROLLKEY", JSON.stringify(false));
      } else {
        state.agreeEnrollKey = true;
        sessionStorage.setItem("AGREE_ENROLLKEY", JSON.stringify(true));
      }
    },
    // 选择考生
    set_checkedUserId(state, value) {
      state.checkedUserId = value;
      sessionStorage.setItem("CHECKED_USERID", JSON.stringify(value));
    },

    // 查看订单
    set_watchOrderId(state, value) {
      state.watchOrderId = value;
      sessionStorage.setItem("WATCH_ORDERID", JSON.stringify(value));
    },
    // 查看准考证
    set_watchCardId(state, value) {
      state.watchCardId = value;
      sessionStorage.setItem("WATCH_CARDID", JSON.stringify(value));
    },
    // 更新来源
    set_userDetaiSource(state, value) {
      state.userDetaiSource = value;
      sessionStorage.setItem("USER_DETAI_SOURCE", JSON.stringify(value));
    },
    // 修改考生信息
    set_editUserId(state, value) {
      state.editUserId = value;
      sessionStorage.setItem("EDIT_USERID", JSON.stringify(value));
    },
    // 选择一级
    set_checkedLevelId(state, value) {
      state.checkedLevelId = value;
      sessionStorage.setItem("CHECKED_LEVELID", JSON.stringify(value));
    },
    // 选择二级
    set_checkedSecondId(state, value) {
      state.checkedSecondId = value;
      sessionStorage.setItem("CHECKED_SECONDID", JSON.stringify(value));
    },
    // 查看视频
    set_watchVideoId(state, value) {
      state.watchVideoId = value;
      sessionStorage.setItem("WATCH_VIDEOID", JSON.stringify(value));
    },
    // 详情页是否显示按钮
    set_agreeBtnShow(state, value) {
      if (value == 1) {
        state.agreeBtnShow = false;
        sessionStorage.setItem("AGREE_BTN_SHOW", JSON.stringify(false));
      } else {
        state.agreeBtnShow = true;
        sessionStorage.setItem("AGREE_BTN_SHOW", JSON.stringify(true));
      }
    },
    // 详情页是否显示按钮
    set_editKey(state, value) {
      if (value == 1) {
        state.editKey = false;
        sessionStorage.setItem("EDIT_KEY", JSON.stringify(false));
      } else {
        state.editKey = true;
        sessionStorage.setItem("EDIT_KEY", JSON.stringify(true));
      }
    },
    // 设置查看条款类型
    set_clauseKey(state, value) {
      state.clauseKey = value;
      sessionStorage.setItem("CLAUSE_KEY", JSON.stringify(value));
    },
    // 设置确认签到准考证号
    set_signinId(state, value) {
      state.signinId = value;
      sessionStorage.setItem("SIGNIN_ID", JSON.stringify(value));
    },
    // 设置签到管理考场id
    set_AdminExaminationId(state, value) {
      state.AdminExaminationId = value;
      sessionStorage.setItem("ADMIN_EXAMINATION_ID", JSON.stringify(value));
    },
    // 设置查看证书id
    set_watchCertificateId(state, value) {
      state.watchCertificateId = value;
      sessionStorage.setItem("WATCH_CERTIFICATEID", JSON.stringify(value));
    },
  },
  actions: {},
  modules: {},
});
