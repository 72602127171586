var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_bottom"},[_c('div',{class:[
      _vm.activeKey == 1
        ? 'home_bottom_item home_bottom_item_isActive'
        : 'home_bottom_item',
    ],on:{"click":function($event){return _vm.toHome()}}},[(_vm.activeKey == 1)?_c('img',{staticClass:"home_bottom_item_img",attrs:{"src":require("../assets/image/home_footer_icon01.png"),"alt":"首页"}}):_vm._e(),(_vm.activeKey != 1)?_c('img',{staticClass:"home_bottom_item_img",attrs:{"src":require("../assets/image/home_footer_icon02.png"),"alt":"首页"}}):_vm._e(),_c('div',{staticClass:"home_bottom_item_name"},[_vm._v("首页")])]),_c('div',{class:[
      _vm.activeKey == 3
        ? 'home_bottom_item home_bottom_item_isActive'
        : 'home_bottom_item',
    ],on:{"click":function($event){return _vm.toUserCenter()}}},[(_vm.activeKey == 3)?_c('img',{staticClass:"home_bottom_item_img",attrs:{"src":require("../assets/image/home_footer_icon05.png"),"alt":"考试列表"}}):_vm._e(),(_vm.activeKey != 3)?_c('img',{staticClass:"home_bottom_item_img",attrs:{"src":require("../assets/image/home_footer_icon06.png"),"alt":"考试列表"}}):_vm._e(),_c('div',{staticClass:"home_bottom_item_name"},[_vm._v("个人信息")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }