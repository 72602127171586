<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="certificate_detail_body">
      <img class="certificate_detail_img" :src="baseAPIUrl + certificateUrl" />
      <div class="certificate_detail_bottom_btn" @click="toBackFn()">返回</div>
    </div>
  </div>
</template>
<style src="../../assets/css/user/certificateDetail.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "CertificateDetail",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "证书", // 顶部组件参数设置
      certificateId: "", // 证书id
      certificateUrl: "", // 证书图片路径
      baseAPIUrl: this.$store.state.baseAPIUrl,
    };
  },
  created: function () {
    let certificateId = this.$route.params.id;
    if (certificateId != undefined && certificateId != "") {
      this.certificateId = certificateId;
      this.$store.commit("set_watchCertificateId", certificateId);
    } else {
      this.certificateId = this.$store.state.watchCertificateId;
    }
    this.getDataFn();
  },
  methods: {
    // 返回
    toBackFn: function () {
      this.$router.go(-1);
    },
    // 获取证书路径
    getDataFn: function () {
      let that = this;
      let url = "/mobile/getStuCert";
      let data = {
        id: this.certificateId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.certificateUrl = response.certificate;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
