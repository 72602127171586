<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="check_body">
      <!-- 内容 -->
      <div class="check_content">
        <!-- 添加考生 -->
        <div class="check_add_user_btn" @click="toAddUserFn()">
          <img
            class="check_add_user_icon"
            src="../../assets/image/add_icon01.png"
          />
          <div class="check_add_user_name">新增考生</div>
        </div>
        <!-- 考生列表 -->
        <div class="check_user_list">
          <div
            class="check_user_list_item"
            v-for="(item, index) in userListShow"
            :key="index"
          >
            <div class="check_user_list_item_left">
              <img
                class="check_user_list_item_icon"
                src="../../assets/image/edit_icon01.png"
                @click.stop="editUserFn(item.id)"
              />
              <div class="check_user_list_item_name">{{ item.name }}</div>
            </div>
            <img
              class="check_user_list_item_delete"
              src="../../assets/image/delete_icon01.png"
              @click="deleteUserFn(item.id)"
            />
          </div>
        </div>
      </div>
      <!-- 确认 -->
      <div class="check_bottom_box">
        <div class="check_bottom_tips">(同一账户不能超过4个）</div>
        <div class="check_bottom_btn" @click="toBackFn()">返回</div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/user/userList.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "UserList",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "考生信息", // 顶部组件参数设置
      userId: "", // 用户id
      userListShow: [], // 考生列表数据
    };
  },
  created: function () {
    this.getListDataFn();
  },
  methods: {
    // 查询考生列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/studentList";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.userListShow = response.list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 返回
    toBackFn: function () {
      this.$router.go(-1);
    },
    // 添加考生
    toAddUserFn: function () {
      if (this.userListShow.length < 4) {
        this.$router.push({
          name: "AddUser",
          params: { source: 1 },
        });
      } else {
        Toast({
          message: "同一账户不能超过4个考生",
          duration: 3000,
        });
      }
    },
    // 编辑考生
    editUserFn: function (id) {
      this.$router.push({
        name: "EditUser",
        params: { userId: id, source: 1 },
      });
    },
    // 删除考生
    deleteUserFn: function (id) {
      let that = this;
      MessageBox.confirm("确认移除该考生?")
        .then((action) => {
          console.log(action);
          let url = "/mobile/removeStudent";
          let data = {
            stu_id: id,
          };
          this.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                that.getListDataFn();
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
