<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="admin_index_body">
      <div>
        <div class="admin_index_body_top">
          <img
            class="admin_index_body_top_img"
            src="../../assets/image/login_img01.png"
          />
          <div class="admin_index_body_top_right">
            <img
              class="admin_index_body_top_title_img"
              src="../../assets/image/card_title01.png"
            />
          </div>
        </div>
        <div class="admin_indexl_card">
          <div class="admin_indexl_top_item">
            <img
              class="admin_indexl_top_img"
              :src="baseAPIUrl + examData.image_path"
              alt="封面"
            />
            <div class="admin_indexl_top_title">{{ examData.title }}</div>
          </div>
          <div class="admin_indexl_top_item">
            <div class="admin_indexl_top_time_box">
              <img
                class="admin_indexl_top_icon"
                src="../../assets/image/time_icon01.png"
              />
              <div class="admin_indexl_top_time">{{ examData.exam_date }}</div>
            </div>
          </div>
          <div class="admin_indexl_top_item_left">
            <img
              class="admin_indexl_top_icon"
              src="../../assets/image/address_icon01.png"
              style="height: auto"
            />
            <div class="admin_indexl_top_address_box">
              <div class="admin_indexl_top_address">
                <div class="admin_indexl_top_address_g">
                  {{ examData.area_name }}
                </div>
                <div class="admin_indexl_top_address_b">
                  {{ examData.place }}
                </div>
              </div>
              <div class="admin_indexl_top_address_detail">
                {{ examData.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="admin_index_body_bottom">
        <div class="admin_index_body_bottom_item" @click="scanQRCodeFn()">
          <img
            class="admin_index_body_bottom_item_img"
            src="../../assets/image/admin_icon01.png"
          />
          <div class="admin_index_body_bottom_item_name">扫码签到</div>
        </div>
        <div class="admin_index_body_bottom_item" @click="toSignListFn()">
          <img
            class="admin_index_body_bottom_item_img"
            src="../../assets/image/admin_icon02.png"
          />
          <div class="admin_index_body_bottom_item_name">签到列表</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/admin/adminIndex.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";
// 引入微信JSSDK
import wx from "weixin-js-sdk";

export default {
  name: "AdminIndex",
  components: {
    HomeTop,
  },
  data() {
    return {
      baseAPIUrl: this.$store.state.baseAPIUrl,
      topTitle: "签到管理",
      wxJSSDKConfig: {}, // 微信JSSDK配置
      realUrl: "",
      examinationId: "", // 考场id
      userid: "",
      isAdmin: 0, // 是否为管理员
      examData: {}, // 考场数据
    };
  },
  created: function () {
    let query = this.$route.query;
    if (query.id) {
      this.examinationId = query.id;
      this.$store.commit("set_AdminExaminationId", this.examinationId);
    } else {
      this.examinationId = this.$store.state.AdminExaminationId;
    }
    this.isExaminerFn();
    this.realUrl = this.$store.state.baseUrl + this.$route.fullPath;
    this.getExamDataFn();
  },
  methods: {
    // 调起扫码
    scanQRCodeFn: function () {
      let that = this;
      if (this.isAdmin != 2) {
        Toast({
          message: "暂无权限",
          duration: 3000,
        });
      } else {
        this.getWXJSSDKConfigFn().then((res) => {
          if (res) {
            wx.config({
              debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
              appId: that.wxJSSDKConfig.appid, // 必填，公众号的唯一标识
              timestamp: that.wxJSSDKConfig.time, // 必填，生成签名的时间戳
              nonceStr: that.wxJSSDKConfig.password, // 必填，生成签名的随机串
              signature: that.wxJSSDKConfig.signature, // 必填，签名
              jsApiList: ["scanQRCode"], // 必填，需要使用的 JS 接口列表
            });
            wx.ready(function () {
              wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                  // let text = JSON.stringify(res);
                  // Toast({
                  //   message: text,
                  //   duration: 3000,
                  // });
                  // let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                  let toObj = JSON.parse(res.resultStr);
                  that.$router.push({
                    name: "AdminSignin",
                    query: {
                      id: toObj.id,
                    },
                  });
                },
              });
            });

            wx.error(function (res) {
              // let text = JSON.stringify(res);
              // Toast({
              //   message: text,
              //   duration: 3000,
              // });
              if (
                res.errMsg == "config:invalid signature" &&
                res.realAuthUrl[0] != "" &&
                res.realAuthUrl[0] != undefined
              ) {
                that.realUrl = res.realAuthUrl[0];
              }
              // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            });
          }
        });
      }
    },
    // 获取WXJSSDK配置
    getWXJSSDKConfigFn: function () {
      let that = this;
      let data = {};
      data = {
        url: that.realUrl,
      };
      let url = "/mobile/getjssdkconfig";
      return new Promise(function (resolve, reject) {
        that.axios
          .post(url, data)
          .then((response) => {
            if (response.code == 0) {
              that.wxJSSDKConfig = response.signpackage;
              resolve(true);
            } else {
              Toast({
                message: response.msg,
                duration: 3000,
              });
              resolve(false);
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    // 判断是否为管理员
    isExaminerFn: function () {
      let that = this;
      let url = "/mobile/examiner/isExaminer";
      let data = {
        exam_id: this.examinationId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.isAdmin = response.is_admin;
            if (response.is_admin != 2) {
              MessageBox({
                message: "不是该考场的管理员,暂无权限管理签到",
                confirmButtonText: "确认",
              }).then(() => {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  function () {
                    window.WeixinJSBridge.call("closeWindow");
                  },
                  false
                );
                window.WeixinJSBridge.call("closeWindow");
              });
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取考场信息
    getExamDataFn: function () {
      let that = this;
      let url = "/mobile/examiner/examDetail";
      let data = {
        exam_id: this.examinationId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.examData = response.exam_info;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 签到列表
    toSignListFn: function () {
      this.$router.push({
        name: "AdminSigninList",
      });
    },
  },
};
</script>
