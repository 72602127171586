import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/home/home.vue";
import Login from "../views/home/login.vue";
import Register from "../views/home/register.vue";
import Card from "../views/home/card.vue";
import Clause from "../views/home/clause.vue";
import NoticeDetail from "../views/home/noticeDetail.vue";

import GradeList from "../views/enroll/gradeList.vue";
import CheckArea from "../views/enroll/checkArea.vue";
import EnrollList from "../views/enroll/enrollList.vue";
import EnrollDetail from "../views/enroll/enrollDetail.vue";
import CheckUser from "../views/enroll/checkUser.vue";
import CheckTime from "../views/enroll/checkTime.vue";
import EnrollAllList from "../views/enroll/enrollAllList.vue";

import OrderDetail from "../views/order/orderDetail.vue";
import OrderSuccess from "../views/order/orderSuccess.vue";

import UserCenter from "../views/user/userCenter.vue";
import UserList from "../views/user/userList.vue";
import UserEnroll from "../views/user/userEnroll.vue";
import UserResult from "../views/user/userResult.vue";
import CertificateDetail from "../views/user/certificateDetail.vue";
import AddUser from "../views/user/addUser.vue";
import EditUser from "../views/user/editUser.vue";

import CourseList from "../views/course/courseList.vue";
import SecondLevelList from "../views/course/secondLevelList.vue";
import VideoList from "../views/course/videoList.vue";
import VideoDetail from "../views/course/videoDetail.vue";

import AdminHome from "../views/admin/adminHome.vue";
import AdminIndex from "../views/admin/adminIndex.vue";
import AdminSignin from "../views/admin/adminSignin.vue";
import AdminApply from "../views/admin/adminApply.vue";
import AdminSigninList from "../views/admin/adminSigninList.vue";

Vue.use(VueRouter);

const routes = [
  // home
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
    },
  }, // 首页
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  }, // 登录
  {
    path: "/register",
    name: "Register",
    component: Register,
  }, // 完善信息
  {
    path: "/card",
    name: "Card",
    component: Card,
    meta: {
      title: "准考证",
    },
  }, // 准考证
  {
    path: "/clause",
    name: "Clause",
    component: Clause,
  }, // 条款页
  {
    path: "/noticeDetail",
    name: "NoticeDetail",
    component: NoticeDetail,
  }, // 须知页
  // enroll
  {
    path: "/gradeList",
    name: "GradeList",
    component: GradeList,
    meta: {
      title: "考试报名",
    },
  }, // 考级列表页
  {
    path: "/checkArea",
    name: "CheckArea",
    component: CheckArea,
    meta: {
      title: "考试报名",
    },
  }, // 选择地区页
  {
    path: "/enrollList",
    name: "EnrollList",
    component: EnrollList,
    meta: {
      title: "考试报名",
    },
  }, // 考试列表页
  {
    path: "/enrollDetail",
    name: "EnrollDetail",
    component: EnrollDetail,
    meta: {
      title: "报名详情",
    },
  }, // 考试详情介绍页
  {
    path: "/checkUser",
    name: "CheckUser",
    component: CheckUser,
    meta: {
      title: "考生选择",
    },
  }, // 考生选择页
  {
    path: "/checkTime",
    name: "CheckTime",
    component: CheckTime,
    meta: {
      title: "选择时间",
    },
  }, // 考试时间选择页
  {
    path: "/enrollAllList",
    name: "EnrollAllList",
    component: EnrollAllList,
    meta: {
      title: "考试列表",
    },
  }, // 全部考试列表页
  // order
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      title: "报名详情",
    },
  }, // 订单详情页
  {
    path: "/orderSuccess",
    name: "OrderSuccess",
    component: OrderSuccess,
    meta: {
      title: "提示",
    },
  }, // 订单完成提示页
  // 用户
  {
    path: "/userCenter",
    name: "UserCenter",
    component: UserCenter,
    meta: {
      title: "个人信息",
    },
  }, // 个人信息页
  {
    path: "/userList",
    name: "UserList",
    component: UserList,
    meta: {
      title: "考生信息",
    },
  }, // 考生信息页
  {
    path: "/userEnroll",
    name: "UserEnroll",
    component: UserEnroll,
    meta: {
      title: "我的报名",
    },
  }, // 我的报名页
  {
    path: "/userResult",
    name: "UserResult",
    component: UserResult,
    meta: {
      title: "成绩查询",
    },
  }, // 成绩查询页
  {
    path: "/certificateDetail",
    name: "CertificateDetail",
    component: CertificateDetail,
    meta: {
      title: "证书",
    },
  }, // 查看证书页
  {
    path: "/addUser",
    name: "AddUser",
    component: AddUser,
    meta: {
      title: "考生信息填写",
    },
  }, // 新增考生页
  {
    path: "/editUser",
    name: "EditUser",
    component: EditUser,
    meta: {
      title: "修改考生信息",
    },
  }, // 修改考生页
  // 考级示范
  {
    path: "/courseList",
    name: "CourseList",
    component: CourseList,
    meta: {
      title: "考级示范",
    },
  }, // 考级示范一级列表页
  {
    path: "/secondLevelList",
    name: "SecondLevelList",
    component: SecondLevelList,
    meta: {
      title: "考级示范",
    },
  }, // 考级示范二级列表页
  {
    path: "/videoList",
    name: "VideoList",
    component: VideoList,
    meta: {
      title: "考级示范",
    },
  }, // 考级示范视频列表页
  {
    path: "/videoDetail",
    name: "VideoDetail",
    component: VideoDetail,
    meta: {
      title: "考级示范",
    },
  }, // 考级示范视频列表页
  // 管理员
  {
    path: "/signin/adminHome",
    name: "AdminHome",
    component: AdminHome,
    meta: {
      title: "签到管理",
    },
  }, // 签到考点页
  {
    path: "/signin/adminIndex",
    name: "AdminIndex",
    component: AdminIndex,
    meta: {
      title: "签到管理",
    },
  }, // 签到管理页
  {
    path: "/signin/adminSignin",
    name: "AdminSignin",
    component: AdminSignin,
    meta: {
      title: "签到管理",
    },
  }, // 确认签到页
  {
    path: "/signin/adminApply",
    name: "AdminApply",
    component: AdminApply,
    meta: {
      title: "签到管理",
    },
  }, // 绑定管理员页
  {
    path: "/signin/adminSigninList",
    name: "AdminSigninList",
    component: AdminSigninList,
    meta: {
      title: "签到管理",
    },
  }, // 确认签到页
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 需要登录的页面
  const nextRoute = [
    "Home",
    "Register",
    "Card",
    "Clause",
    "NoticeDetail",
    "GradeList",
    "CheckArea",
    "EnrollList",
    "EnrollDetail",
    "CheckUser",
    "CheckTime",
    "EnrollAllList",
    "OrderDetail",
    "OrderSuccess",
    "UserCenter",
    "UserList",
    "UserEnroll",
    "UserResult",
    "CertificateDetail",
    "AddUser",
    "EditUser",
    "CourseList",
    "SecondLevelList",
    "VideoList",
    "VideoDetail",
  ];
  const adminNextRoute = ["AdminIndex", "AdminSignin", "AdminSigninList"];
  let isLogin = sessionStorage.getItem("LOGIN_TOKEN"); // 判断是否登录，本地存储有用户数据则视为已经登录
  let isAdminLogin = sessionStorage.getItem("ADMIN_LOGIN_TOKEN"); // 判断是否登录，本地存储有用户数据则视为已经登录

  // 未登录状态；当路由到 nextRoute 指定页时，跳转至 登录
  if (nextRoute.indexOf(to.name) >= 0) {
    // 检测是否登录的页面
    if (!isLogin) {
      // 如果未登录（本地存储无用户数据），并且要跳到登录页面
      if (from.name === "Login") {
        next("/login");
        return;
      }
      // 登录后，跳到到当前页面
      router.push({
        name: "Login",
        params: {
          redirect: to.fullPath,
        },
      });
    }
  }

  // 考场管理
  if (adminNextRoute.indexOf(to.name) >= 0) {
    // 检测是否登录的页面
    if (!isAdminLogin) {
      // 登录后，跳到到当前页面
      router.push({
        name: "AdminHome",
      });
    }
  }

  // 已登录状态；当路由到 登录 时，跳转至 首页
  if (to.name === "Login") {
    if (isLogin) {
      next("/");
      return;
    }
  }
  next(); // 必须使用 next ,执行效果依赖 next 方法的调用参数
});

router.afterEach((to) => {
  const u = navigator.userAgent.toLowerCase();
  if (
    u.indexOf("like mac os x") < 0 ||
    u.match(/MicroMessenger/i) != "micromessenger"
  )
    return;
  if (to.path !== global.location.pathname) {
    location.assign(to.fullPath);
  }
});

export default router;
