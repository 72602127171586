<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="admin_signin_select_time" @click="openPopupFn()">
      <div class="admin_signin_select_time_input">
        <div class="admin_signin_select_time_input_l">
          {{ selectTimeLabelShow }}
        </div>
        <div class="admin_signin_select_time_input_r">
          <div class="admin_signin_select_time_input_r_b">
            总人数：{{ total_count }}
          </div>
          <div class="admin_signin_select_time_input_r_b">
            已签到：{{ total_signin_count }}
          </div>
          <div class="admin_signin_select_time_input_r_b">
            未签到：
            <div class="admin_signin_select_time_input_r_r">
              {{ total_unsignin_count }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <mt-popup
      class="admin_signin_popup"
      v-model="popupVisible"
      position="bottom"
    >
      <div class="admin_signin_popup_btnBox">
        <div class="select_time_cancel" @click="selectTimeCancel()">取消</div>
        <div class="select_time_confirm" @click="selectTimeConfirm()">确定</div>
      </div>
      <mt-picker
        :slots="slots"
        value-key="exam_time_start"
        @change="onValuesChange"
      ></mt-picker>
    </mt-popup>
    <div class="admin_signin_lsit_box">
      <div class="admin_signin_lsit_content">
        <div
          class="admin_signin_lsit_item"
          v-for="(item, index) in adminSigninLsit"
          :key="index"
        >
          <div class="admin_signin_lsit_item_left">
            <div class="admin_signin_lsit_item_name">{{ item.stu_name }}</div>
            <div class="admin_signin_lsit_item_number">
              准考证号：{{ item.exam_stu_number }}
            </div>
            <div class="admin_signin_lsit_item_time" v-if="item.signin == 2">
              签到时间：{{ item.signin_time }}
            </div>
            <div class="admin_signin_lsit_item_number">
              家长姓名：{{ item.name }}
            </div>
            <div class="admin_signin_lsit_item_number">
              手机号：<a :href="'tel:' + item.phone">{{ item.phone }}</a>
            </div>
          </div>
          <div
            class="admin_signin_lsit_item_btn"
            @click="stuSigninFn(item.id)"
            v-if="item.signin == 2"
          >
            取消签到
          </div>
          <div
            class="admin_signin_lsit_item_btn signin_status"
            v-if="item.signin == 1"
          >
            未签到
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/admin/adminSigninList.css" scoped></style>

<script>
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "AdminSigninList",
  components: {
    HomeTop,
  },
  data() {
    return {
      topTitle: "签到列表",
      wxJSSDKConfig: {}, // 微信JSSDK配置
      examinationId: "", // 考场id
      adminSigninLsit: [], // 签到列表数据
      popupVisible: false, // 时间选择显示
      selectTimeLabel: "请选择时间", // 筛选时间-文本
      selectTimeId: "", // 筛选时间-id
      selectTimeLabelShow: "请选择时间", // 筛选时间-文本显示
      selectTimeIdShow: "", // 筛选时间-id显示
      selectTimeDatalist: [], // 筛选时间列表
      slots: [
        {
          flex: 1,
          values: [],
          textAlign: "center",
        },
      ],
      total_count: 0, // 总人数
      total_signin_count: 0, // 签到人数
      total_unsignin_count: 0, // 未签到人数
    };
  },
  created: function () {
    this.examinationId = this.$store.state.AdminExaminationId;
    this.getlistDataFn();
    this.getSelectTimeDatalistFn();
  },
  methods: {
    onValuesChange: function (picker, values) {
      if (values[0]) {
        this.selectTimeId = values[0].id;
        if (values[0].id == "") {
          this.selectTimeLabel = "请选择时间";
        } else {
          this.selectTimeLabel = values[0].exam_time_start;
        }
      }
    },
    openPopupFn: function () {
      this.popupVisible = true;
    },
    selectTimeCancel: function () {
      this.selectTimeId = this.selectTimeIdShow;
      this.selectTimeLabel = this.selectTimeLabelShow;
      this.popupVisible = false;
    },
    selectTimeConfirm: function () {
      this.selectTimeIdShow = this.selectTimeId;
      this.selectTimeLabelShow = this.selectTimeLabel;
      this.popupVisible = false;
      this.getlistDataFn();
    },
    // 查询时间筛选列表数据
    getSelectTimeDatalistFn: function () {
      let that = this;
      let url = "/mobile/examiner/examTimeList";
      let data = {
        exam_id: this.examinationId,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            let obj = {
              exam_time_start: "无",
              id: "",
            };
            that.slots[0].values = response.list;
            that.slots[0].values.unshift(obj);
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查询列表数据
    getlistDataFn: function () {
      let that = this;
      let url = "/mobile/examiner/stuSigninList";
      let data = {
        exam_id: this.examinationId,
        exam_qid: this.selectTimeIdShow,
      };
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.adminSigninLsit = response.enroll_info;
            that.total_count = response.total_count;
            that.total_signin_count = response.total_signin_count;
            that.total_unsignin_count = response.total_unsignin_count;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 取消签到
    stuSigninFn: function (id) {
      let that = this;
      let url = "/mobile/examiner/stuSignin";
      let data = {
        enroll_id: id,
        signin: 1,
      };
      MessageBox({
        message: "是否取消签到",
        confirmButtonText: "确认",
        showCancelButton: true,
      }).then((action) => {
        if (action == "confirm") {
          this.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: "取消签到成功",
                  duration: 3000,
                });
                that.getlistDataFn();
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
