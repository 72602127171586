<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="video_list_body">
      <div class="video_list">
        <div
          class="video_list_item"
          v-for="(item, index) in secondList"
          :key="index"
          @click="toVideoDetailFn(item.id)"
        >
          <img
            v-if="item.key == 1"
            class="video_list_item_img"
            src="../../assets/image/course_bg01.png"
          />
          <img
            v-if="item.key == 2"
            class="video_list_item_img"
            src="../../assets/image/course_bg02.png"
          />
          <img
            v-if="item.key == 3"
            class="video_list_item_img"
            src="../../assets/image/course_bg03.png"
          />
          <img
            v-if="item.key == 4"
            class="video_list_item_img"
            src="../../assets/image/course_bg04.png"
          />
          <div class="video_list_item_right">
            <div class="video_list_item_title">
              {{ item.index + "." + item.title }}
            </div>
            <div class="video_list_item_time"></div>
            <img
              class="video_list_item_icon"
              src="../../assets/image/to_icon01.png"
            />
          </div>
        </div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/course/videoList.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import CourseListData from "../../../public/data/course.json";

export default {
  name: "VideoList",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 1,
      topTitle: "",
      listShowData: [], // 列表数据
      levelId: "", // 一级Id
      secondId: "", // 二级Id
      secondList: [],
    };
  },
  created: function () {
    this.levelId = this.$store.state.checkedLevelId;

    let v = this.$route.params.secondId;
    if (v != undefined && v != "") {
      this.secondId = v;
      this.$store.commit("set_checkedSecondId", v);
    } else {
      this.secondId = this.$store.state.checkedSecondId;
    }
    this.setListFn(this.levelId, this.secondId);
  },
  methods: {
    // 设置列表数据
    setListFn: function (id, secondId) {
      let that = this;
      let data = CourseListData.data;
      let list = [];
      data.forEach(function (item) {
        if (item.key == id) {
          list = item.children;
        }
      });
      list.forEach(function (item) {
        if (item.key == secondId) {
          that.secondList = item.video;
          that.topTitle = item.value;
        }
      });
    },
    // 选择视频列表
    toVideoDetailFn: function (videoId) {
      this.$router.push({
        name: "VideoDetail",
        params: {
          videoId: videoId,
        },
      });
    },
  },
};
</script>
