import Axios from "axios";
import store from "../store";
import vue from "vue";

Axios.defaults.baseURL = "/api";
// Axios.defaults.baseURL = "https://";
// request请求拦截器
Axios.defaults.withCredentials = true;
vue.prototype.$axios = Axios;
//请求超时时间
Axios.defaults.timeout = 100000;
Axios.defaults.headers.get["Content-Type"] = "application/json";
Axios.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.getItem("LOGIN_TOKEN"));
    const adminToken = JSON.parse(sessionStorage.getItem("ADMIN_LOGIN_TOKEN"));
    if (
      config.url.indexOf("/mobile/login") == -1 &&
      config.url.indexOf("/mobile/examiner/examinerLogin") == -1
    ) {
      if (config.url.indexOf("/mobile/examiner") == -1) {
        if (token) {
          config.headers.Authorization = `${token}`;
        }
      }
      if (config.url.indexOf("/mobile/examiner") != -1) {
        if (adminToken) {
          config.headers.Authorization = `${adminToken}`;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone返回拦截器
Axios.interceptors.response.use(
  (response) => {
    if (
      response.data.code == -1 ||
      response.data.code == -2 ||
      response.data.code == -3
    ) {
      sessionStorage.removeItem("LOGIN_TOKEN");
      sessionStorage.removeItem("ADMIN_LOGIN_TOKEN");
      location.reload();
    }
    if (response.data.code == -4) {
      store.commit("set_isRegister", 1);
      console.log(store.state.isRegister);
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
