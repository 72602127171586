<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="enroll_body">
      <!-- 报名列表 -->
      <div class="enroll_list" v-if="enrollListShow.length > 0">
        <div
          class="enroll_list_item"
          v-for="(item, index) in enrollListShow"
          :key="index"
          @click="toOrderDetailFn(item.id)"
        >
          <div class="enroll_list_item_title_box">
            <div class="enroll_list_item_title">{{ item.title }}</div>
            <div class="enroll_list_item_price">￥ {{ item.exam_money }}</div>
          </div>
          <div class="enroll_list_item_user_box">
            <div class="enroll_list_item_user">
              <img
                class="enroll_list_item_user_icon"
                src="../../assets/image/user_icon01.png"
              />
              <div class="enroll_list_item_name">
                {{ item.stu_name }}
              </div>
            </div>
            <div
              class="enroll_list_item_status_o"
              v-if="item.order_status == 1"
            >
              待支付
            </div>
            <div
              class="enroll_list_item_status_g"
              v-if="item.order_status == 2"
            >
              已支付
            </div>
            <div
              class="enroll_list_item_status_h"
              v-if="item.order_status == 6"
            >
              已退款
            </div>
            <div
              class="enroll_list_item_status_o"
              v-if="item.order_status == 7"
            >
              退款中
            </div>
          </div>
          <div class="enroll_list_item_bottom">
            <div class="enroll_list_item_bottom_left">
              <div class="enroll_list_item_address">
                <img
                  class="enroll_list_item_address_icon"
                  src="../../assets/image/address_icon01.png"
                />
                <div class="enroll_list_item_address_value">
                  {{ item.place }}
                </div>
              </div>
              <div class="enroll_list_item_time">
                <img
                  class="enroll_list_item_time_icon"
                  src="../../assets/image/time_icon01.png"
                />
                <div class="enroll_list_item_time_value">
                  {{ item.exam_date + " " + item.exam_start_time }}
                </div>
              </div>
            </div>
            <div
              class="enroll_list_item_btn"
              v-if="item.order_status == 1"
              @click.stop="cancelBtnFn(item.id)"
            >
              取消报名
            </div>
            <div
              class="enroll_list_item_btn"
              v-if="item.order_status == 2 && item.status == 1"
              @click.stop="orderRefundBtn(item.id)"
            >
              申请退款
            </div>
          </div>
        </div>
      </div>
      <div class="empty_box" v-else>
        <img class="empty_icon" src="../../assets/image/empty_icon01.png" />
        <div class="empty_name">暂无报名</div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/user/userEnroll.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { MessageBox, Toast } from "mint-ui";

export default {
  name: "UserEnroll",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "我的报名", // 顶部组件参数设置
      userId: "", // 用户id
      enrollListShow: [],
    };
  },
  created: function () {
    this.userId = this.$store.state.loginUserId;
    this.getListDataFn();
  },
  methods: {
    // 查询考场列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/myenrolls";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.enrollListShow = response.orderinfo;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查看详情
    toOrderDetailFn: function (id) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          orderId: id,
        },
      });
    },
    // 取消订单
    cancelBtnFn: function (id) {
      let that = this;
      let url = "/mobile/cancelOrderEnroll";
      let data = {
        order_id: id,
      };
      MessageBox.confirm("确认取消报名?")
        .then((action) => {
          console.log(action);
          that.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: "已取消报名",
                  duration: 3000,
                });
                that.getListDataFn();
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 申请退款
    orderRefundBtn: function (id) {
      let that = this;
      let url = "/wechatpay/submitRefundApplication";
      let data = {
        order_id: id,
      };
      MessageBox.confirm("确认申请退款?")
        .then((action) => {
          console.log(action);
          that.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: "已申请退款",
                  duration: 3000,
                });
                that.getListDataFn();
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
