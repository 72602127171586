<template>
  <div class="page_body">
    <HomeTop :topTitle="topTitle"></HomeTop>
    <div class="result_list_body">
      <!-- 报名列表 -->
      <div class="result_list" v-if="listDataShow.length > 0">
        <div
          class="result_list_item"
          v-for="(item, index) in listDataShow"
          :key="index"
        >
          <div class="result_list_item_title_box">
            <div class="result_list_item_title">{{ item.title }}</div>
            <div class="result_list_item_status_o" v-if="item.pass_status == 1">
              不合格
            </div>
            <div class="result_list_item_status_g" v-if="item.pass_status == 2">
              合格
            </div>
            <div class="result_list_item_status_g" v-if="item.pass_status == 3">
              良好
            </div>
            <div class="result_list_item_status_g" v-if="item.pass_status == 4">
              优秀
            </div>
          </div>
          <div class="result_list_item_user_box">
            <div class="result_list_item_time">
              <img
                class="result_list_item_time_icon"
                src="../../assets/image/time_icon01.png"
              />
              <div class="result_list_item_time_value">
                {{ item.exam_date + " " + item.exam_start_time }}
              </div>
            </div>
            <div class="result_list_item_result" v-if="item.pass_status != 1">
              <div class="result_list_item_result_title">分数:</div>
              <div class="result_list_item_result_value">
                {{ item.score }}分
              </div>
            </div>
          </div>
          <div
            :class="[
              item.pass_status != 1
                ? 'result_list_item_bottom has_certificate'
                : 'result_list_item_bottom',
            ]"
          >
            <div class="result_list_item_bottom_left">
              <div class="result_list_item_user">
                <img
                  class="result_list_item_user_icon"
                  src="../../assets/image/user_icon01.png"
                />
                <div class="result_list_item_name">{{ item.stu_name }}</div>
              </div>
            </div>
            <div
              class="result_list_item_certificate"
              v-if="item.pass_status != 1"
              @click="toCertificate(item.id)"
            >
              查看证书
            </div>
            <div class="result_list_item_result" v-if="item.pass_status == 1">
              <div class="result_list_item_result_title">分数:</div>
              <div class="result_list_item_result_value">
                {{ item.score }}分
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty_box" v-else>
        <img class="empty_icon" src="../../assets/image/empty_icon01.png" />
        <div class="empty_name">暂无成绩</div>
      </div>
    </div>
    <HomeFooter :activeKey="activeKey"></HomeFooter>
  </div>
</template>
<style src="../../assets/css/user/userResult.css" scoped></style>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import HomeTop from "@/components/homeTop.vue";
import { Toast } from "mint-ui";

export default {
  name: "UserResult",
  components: {
    HomeFooter,
    HomeTop,
  },
  data() {
    return {
      activeKey: 2, // 底部组件参数设置
      topTitle: "成绩查询", // 顶部组件参数设置
      userId: "", // 用户id
      listDataShow: [],
    };
  },
  created: function () {
    this.userId = this.$store.state.loginUserId;
    this.getListDataFn();
  },
  methods: {
    // 查询考场列表数据
    getListDataFn: function () {
      let that = this;
      let url = "/mobile/myscores";
      let data = {};
      this.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.listDataShow = response.list;
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 参看证书
    toCertificate: function (id) {
      this.$router.push({
        name: "CertificateDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>
